import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showError } from "../../constants";
import { useLazyUserJourneyQuery } from "../../services/advertiser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "../../components";
import useTranslation from "../../hooks/Translation";

const Journeys = () => {
  const navigate=useNavigate()
  const {id}=useParams()
  const [journey,setJourney]=useState<any>([])
  const [totalCount,setTotalCount]=useState<number>(0)
  const [getJourney,{isLoading}]=useLazyUserJourneyQuery()
  const [page, setPage] = useState<number>(1);
const translation=useTranslation()as any

  const fetchJourney=async(id:string|undefined)=>{
    try{
        const response:any= await getJourney({
          id:id,
          page:page,
          limit:10
        }).unwrap()
        console.log(response)
        if(response?.statusCode===200){
          setJourney(response?.data?.journey)
          setTotalCount(response?.data?.journeyCount)
        }else{
          setJourney([])
        }
    }catch(error:any){
      showError(error?.data?.message||"")
    }

  }


  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(()=>{
    if(id){
      fetchJourney(id)
    }
  },[page])

let totalPages=Math.ceil(totalCount/10)
  return (
    <div>
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">{translation.manage_users_details.active_comp_journey||""}</h2>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{translation.table_heads.sno||""}</TableCell>
              <TableCell align="center">{translation.table_heads.img_vid||""}</TableCell>
              <TableCell>{translation.table_heads.bucket_title||""}</TableCell>
              {/* <TableCell>Description</TableCell> */}
              {/* <TableCell>Location</TableCell> */}
              <TableCell>{translation.table_heads.journey_type||""}</TableCell>
              <TableCell>{translation.table_heads.category||""}</TableCell>
              <TableCell>{translation.table_heads.status||""}</TableCell>
              <TableCell>{translation.table_heads.actions||""}</TableCell>

              {/* <TableCell>Target Date</TableCell> */}
            </TableRow>
          </TableHead>

          {journey?.length?(
          
            journey?.map((item:any,index:any)=>(
                <TableBody key={index}>
                  <TableRow>
                    <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>
                    {item?.uploads[0]?.type==="VIDEO"?(
                 
                 <video
                 autoPlay
                 muted
                 loop
                 playsInline
                 className="hm_vid"
                 preload="yes"
                      
                 key={item?._id}
               >
                 <source  src={
                       item?.uploads[0]?.link ||
                       "/static/images/post.png"
                     }
                      type="video/mp4" />
               </video>
           ):(
             <figure className="bnr_img">
               <img
                 src={
                   item?.uploads[0]?.link ||
                   "/static/images/post.png"
                 }
                 alt=""
               />
             </figure>
           )}
                    </TableCell>

                    <TableCell>
                      {item?.title || ""}
                    </TableCell>
                    <TableCell>
                      {item?.isPrivate===true?"Private":"Public"}
                    </TableCell>
                   <TableCell>
                    {item?.categoryName}
                    </TableCell> 
                    <TableCell>
                       {item?.isPrivate===false?"Public":"Private"}
                    </TableCell>
                    <TableCell>
                    <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage-journeys/details/${item?._id}`,
                              {
                                state: { page: "User" },
                              },
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
            ))
          ):(
            <TableBody>
            <TableCell
              align="center"
              colSpan={10}
              sx={{ color: "#051140" }}
            >
              No Journey Found
            </TableCell>
          </TableBody>
          )}


{/* 
          <TableBody>
            <TableRow>
              <TableCell align="center">{1}</TableCell>
              <TableCell align="center">
                <figure className="user_img">
                  <img src="/static/images/post.png" alt="" />
                </figure>
              </TableCell>
              <TableCell>Going to Paris</TableCell>
              {/* <TableCell>Lore</TableCell> */}
              {/* <TableCell>Bhumibandara Hotel</TableCell>{" "} */}
              {/* <TableCell>Private</TableCell>
              <TableCell>Travel</TableCell>
              {/* <TableCell></TableCell> */}
              {/* <TableCell>Completed</TableCell> */}
              {/* <TableCell>Accepted</TableCell> */}
            {/* </TableRow> */}
          {/* </TableBody>  */} 
        </Table>
      </TableContainer>
    </Card>
    <Pagination
    module={journey}
    totalPages={totalPages}
    page={page}
    onPageChange={onPageChange}
    />
    </div>
  );
};

export default Journeys;
