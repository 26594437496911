import { END_POINTS } from "../constants/url";
import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: DashboardData },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.dashboard}`,
        method: "GET",
      }),
    }),
    getUserGraph: builder.query<
      CommonResponseType & { data: any },
      { type: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.userGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    getAdvertiserGraph: builder.query<
      CommonResponseType & { data: any },
      { type: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.advertiserGraph}?type=${type}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetDashboardQuery,
  useLazyGetUserGraphQuery,
  useLazyGetAdvertiserGraphQuery,
} = dashboardApi;
