export const en = {
  Globals: {
    Login: "Login",
    Continue: "Continue",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    export_csv: "Export CSV",
    back: "Back",
    save: "save",
    send: "Send",
    submit: "Submit",
    select: "Select",

    //new
    login_to: "Log in to Ambitio Admin",
    email_address: "Email Address",
    password: "Password",
    myProfile: "My Profile",
    change_password: "Change Password",
    logout: "Logout",
    old_password: "Old Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    remember_me: "Remember me",
    forgot_password: "Forgot Password",
    email_required: "Email is required!",
    valid_email: "Enter a valid email address!",
    password_required: "Password is required!",
    valid_password: "Password must be at least 6 characters",
    want_to_logout: "Are you sure, you want to logout?",
    yes: "Yes",
    no: "No",
    search: "Search here",
    showing: "Showing",
    items: "items",
    delete_this: "Are you sure you want to delete this",
    deleted_successfully: "Deleted Successfully",
    edit_profile: "Edit User Profile",
    view_profile: "View User Profile",
    address: "Address",
    active: "Active",
    blocked: "Blocked",
    accept: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
    advertiser: "advertiser",
    full_name_req: "Full name is required",
    min_2: "Minimum 2 characters are required",
    max_80: "Maximum 80 characters are allowed",
    phone_required: "Phone number is required",
    phone_6: "Phone number must be at least 6 characters",
    phone_16: "Phone number must be at least 16 characters",
    address_req: "Address is required",
    max_100: "Maximum 100 character are allowed",
    city_req: "City is required",
    state_req: "State is required",
    country_req: "Country is required",
    field_req: "This field is required",
    min_4: "Minimum 4 characters are required",
    max_12: "Maximum 12 character are allowed",
    front_img_req: "Document front image is required",
    back_img_req: "Document back image is required",
    advertiser_update: "Advertiser updated successfully!",
    advertiser_create: "Advertiser created successfully!",
    no_stories: "No Stories Found",
    category_name_req: "Category name is required",
    category_update: "Category updated successfully!",
    category_create: "Category created successfully!",
    image: "Image",
    category_name_en: " Category Name (English)",
    category_name_ar: "Category Name (Arabic)",
    edit_category: "Edit Challenge Category",
    add_category: "Add Challenge Category",
    plan_type: "Select plan type",
    feature: "Select atleast one feature",
    subs_update: "Subscription Updated",
    subs_add: "Subscription Added",
    Half_Yearly: " Half Yearly",
    basic: "Basic plan cannot be deleted",
    basic_edit: "Basic plan is not editable",
    no_subs: " No Subscriptions Found",
    subs_plan: "subscription plan",
    min_1: "Minimum 1 characters are required",
    rewards_update: "Rewards Updated SuccessFully",
    reward: "reward",
    no_reward: "No Rewards found",
    cancel: "Cancel",
    compalaint: "Complaint",
    no_allowed: "No of Allowed Entries",
    update: "Updated Successfully",
    img_vid: "Image or video cannot be empty",
    select_img: "Please select images and videos to upload",
    challenge: "challenge",
    word_update: "Word updated successfully!",
    word_create: "Word created successfully!",
    word: "word",
    gift_update: "Gift Updated Successfully",
    gift_add: "Gift added successfully",
    gift: "gift",
    notification: "notification",
    select_not: "Select user or select checkbox",
    notification_send: "Notification sent successfully",
    receiver: " Select Receiver",
    fname_req: "First name is required",
    min_3: "Minimum 3 characters are required",
    max_30: "Maximum 30 character are allowed",
    allow_permission: "Please allow permission for atleast one module",
    subadmin_update: "Subadmin Updated Sucessfully",
    subadmin_create: "Sub-admin Created Sucessfully",
    phone: "Phone Number",
    old: "Old Password",
    old_req: "Old password is required.",
    new: "new Password",
    new_req: "New password is required.",
    cannot: "New password cannot be same as old password.",
    match: "Passwords must match.",
    confirm: "Confirm password is required",
    pw_change: "Password changed successfully",
    new_pw: "New Password",
    cnfrm: "Confirm Password",
    change: "Change Password",
    verify_otp: "Verify Otp",
    resend: "Resend OTP",
    expire: "The verification code will expire in",
    reset_pw: "Reset Password",
    dashboard: "Dashboard",
    manage_user: "Manage Users",
    manage_Advertiser: "Manage Advertisers",
    manage_advertisement: "Manage Advertisements",
    manage_content: "Manage Content/Posts",
    manage_journey: "Manage Journey",
    manage_challenge: "Manage Challenge Categories",
    manage_subs: "Manage Subscription Plans",
    manage_rewards: "Manage Rewards",
    customer: "Customer Support",
    manage_challenge_dash: "Manage Challenges",
    manage_word: "Manage Words",
    manage_payment: "Manage Payments",
    manage_gift: "Manage Gifts",
    manage_notification: "Manage Notifications",
    report: "Reports and Analytics",
    manage_subadmin: "Manage Sub-Admin",
    cms: "Manage CMS",
    faq: "Manage FAQs",
    subadmin: "subadmin",
    enable: "Enabled successfully",
    no_ads: " No ads payment found",
    faq_add: "FAQ added successfully",
    faq_del: "Faq deleted successfully!",
    no_adver: " No Advertisers Found",

    challenge_created: "Challenge created successfully",
    create_challenge: "Create Challenge",
    add_journey: "Add Journey",
    only_video: "You can upload only video",
    journey_video: "Upload Journey Video",
    title: "Title",
    description: "Description",
    location: "Location",
    journey_type: "Journey Type",
    category: "Category",
    private: "Private",
    public: "Public",
    target_date: "Target Date",
    video_too_long:
      "Video too long. Please add video of duration less than 30s",
    journey_created: "Journey created successfully",
    targetDate: "Target Date",
    cat_req: "Category is required",
    upload_video: "Please upload journey video",
    all_journeys: "All Journeys",
    created_by: "Created By",
    liveStreaming: "Live Streaming history",
    comments_enabled: "Comments Enabled",
    noOfUsers: "No. of users joined",
    no_live_history: "No live streaming history found",
  },

  warnmodal: {
    desc: "Are you sure you want to delete this",
  },

  toast: {
    enabled: "Enable Successfully",
    disabled: "Disabled Successfully",
  },

  table_heads: {
    sno: "S.No",
    username: "Username",
    phone: "Phone No.",
    phone_number: "Phone Number",
    payment_received: "Payment Received",
    payment_mode: "Payment Mode",
    email: "Email",
    fname: "Full Name",
    account_status: "Account Status",
    actions: "Actions",
    journey_type: "Journey Type",
    bucket_title: "Bucket Title",
    category: "Category",
    status: "Status",
    img_vid: "Image/Video",
    challenge_title: "Challenge Title",
    location: "Location",
    post: "Post",
    post_description: "Post Description",
    date_time: "Upload Date and Time",
    story: "Story",
    text: "Text",
    duration: "Duration",
    contact_number: "Contact Number",
    approval_status: "Approval Status",
    ad_image: "Ad Image",
    advertiser_details: "Advertiser Details",
    ad_title: "Ad Title",
    web_link: "Web Link",
    created_at: "Created At",
    created_Date: "Created Date",
    total_cost: "Total Cost",
    image: "Image",
    created_on: "Created On",
    price: "Price",
    url: "Url",
    user_details: "User Details",
    reported_by: "Reported By",
    comments: "Comment",
    report: "Report",
    likes: "Likes",
    replies: "No. of Replies",
    journey: "Journey",
    journey_desc: "Journey Description",
    upload_date: "Upload Date",
    category_name: "Category Name",
    plane_name: "Plan Name",
    plan_validity: "Plan Type",
    plan_price: "Plan Price",
    features: "Feature",
    badge_image: "Badge Image",
    name: "Name",
    points_earned: "Points Earned",
    no_of_journey: "No. of Journeys",
    no_of_post: "No. of Posts",
    no_of_challenge: "No. of Challenges",
    queries: "Queries",
    title: "Title",
    desc: "Description",
    allowed_entries: "Allowed Entries",
    word: "Word",
    gift_image: "Gift Image",
    gift_title: "Gift Title",
    gift_worth: "Gift Worth",
    date: "Date",
    delete: "Delete",
    message: "Message",
  },

  dashboard: {
    dashboard: "Dashboard",
    total_users: "Total Users",
    total_post: "Total Posts",
    total_chlg: "Total Challenges",
    total_journey: "Total Journeys",
    total_earning: "Total Earnings",
  },

  graph_menu: {
    users: "Users",
    earning: "Earnings",
    daily: "Daily",
    select: "Select",
    monthly: "Monthly",
    weekly: "Weekly",
    yearly: "Yearly",
    day: "Day",
    week: "Week",
  },

  manage_users: {
    manage_user: "Manage Users",
    no_user_found: "No Users Found",
    user: "user",
  },

  manage_users_details: {
    user_details: "User Details",
    active_comp_journey: "Active and Completed Journey",
    active_comp_challenge: "Active and Completed Challenges",
    posts: "Posts",
    stories: "Stories",
  },

  manage_advertisers: {
    manage_advertiser: "Manage Advertisers",
    add_advertisers: "Add Advertiser",
  },
  advertiser_details: {},
  add_advertiser: {
    edit: "Edit Advertiser",
    add: "Add Advertiser",
    image: "Image",
    fname: "Full Name",
    email: "Email",
    phone: "Phone Number",
    address: "Address",
    city: "City",
    state: "State",
    country: "Country",
    zip: "PIN Code/Zipcode",
    upload_docs: " Upload Documents",
    doc_name: "Name of the Document",
    expiry: "Expiry Date",
    front: " Document Front Image",
    back: " Document back Image",
  },
  manage_advertisements: {
    advertisement: "advertisement",
    no_found: "  No Advertisements Found",
    approve: "APPROVE",
    reject: "REJECT",
    select: "Select",
  },

  manage_advertisements_details: {
    view_details: "View Advertisement Details",
    image: "Advertisement Image",
    advertiser_details: "Advertiser Details",
    advertiser_name: "Advertiser Name",
    email: "Email",
    phone: "Phone no",
    title: "Title",
    web_link: "Web Link",
    no_of_hours: "No. of hours",
    hourly_charge: "Hourly Charge",
    total_cost: "Total Cost",
    created_date: "Created Date",
    approval: "Approval",
    accept: "Accept",
    reject: "Reject",
    fname: "Full Name",
    approval_status: "Approval Status",
    doc_name: "Name of the Document",
    expiry: "Document Expiry Date",
    address: "Address",
    ads_activated: "No. of Ads Activated",
    ads_deactivated: "No. of Ads Deactivated",
    ads_completed: "Completed Ads",
    docs: "Documents",
    ads_history: "Ads History",
  },
  manage_content: {
    all_post: "All Posts",
    reported_post: "Reported Posts",
    not_found: "No Post Found",
    content_details: "View Content Details",
    user_details: "User Details",
    user_name: "User Name",
    phone: "Phone no",
    email: "Email",
    details: "Content Details",
    uploads: "Uploads",
    desc: "Description",
    upload_date: "Upload Date",
    total_comments: "Total Comments",
    total_likes: "Total Likes",
    reported_comments: "Reported Comments",
    new_comments: "New Comments",
    not_comments: "No Comments Found",
  },
  manage_journey: {
    manage_journey: "Manage Journey",
    reported_journey: "Reported Journey",
    not_found: "No Journey Found",
    journey_details: "Journey Details",
    fname: "Full Name",
    userName: "Username",
    phone: "Phone No",
    email: "Email",
    uploads: "Uploads",
    title: "Journey Title",
    desc: "Description",
    upload_date: "Upload Date",
    upload_time: "Upload Time",
    category: "Category",
    total_comments: "Total Comments",
    total_likes: "Total Likes",
    share_count: "Share Count",
    reported_comments: "Reported Comments",
    new_comments: "New Comments",
    no_comments: "No Comments Found",
    no_replies: "No Replies Found",
    replies: "Replies",
    reply: "Reply",
    date: "Date",
  },

  manage_categories: {
    manage_categories: "Manage Challenge Categories",
    add_category: "Add Category",
    not_found: "No Category Found",
  },
  manage_subscriptions: {
    manage_subscription: "Manage Subscription Plans",
    add_plan: "Add Plan",
    add_feature: "Add Feature",
    cancel: "Cancel",
    send: "Send",
    edit: "Edit Plan",
    plan_name_eng: "Plan Name (English)",
    plan_name_arb: "Plan Name (Arabic)",
    plan_validity: "Plan Validity",
    plan_type: "Plan Type",
    plan_price: "Plan Price",
    desc_eng: "Description (English)",
    desc_arb: "Description (Arabic)",
    plan_features: "Plan Features",
  },
  manage_rewards: {
    manage_rewards: "Manage Rewards",
    add: "Add Badge",
    image: "Image",
    title_eng: "Title (English)",
    title_arb: "Title (Arabic)",
    points_earned: "Points Earned",
    no_of_journey: "NO. of Journey",
    no_of_post: "NO. of Posts",
    no_of_challenge: "No. of Challenges",
  },

  customer_support: {
    customer_support: "Customer Support",
    queries_received: "Queries Received",
    complaint_dispute: "Complatints/Disputes",
    not_found: "No Queries Found",
    revert: "Revert Query",
    view_details: "View Query Details",
    user_details: "User Details",
    fname: "Full Name",
    phone: "Phone no",
    email: "Email",
    query_details: "Query Details",
    desc: "Description",
    date: "Date",
    status: "Status",
    reply: "Reply",
  },
  manage_challenge: {
    manage_challenge: "Manage Challenges",
    challenge_points: "Challenge Points",
    add: "Add",
    not_found: "No Challenges Found",
    challeneg_details: "Challenge Details",
    user_image: "User Image",
    username: "Username",
    email: "Email",
    phone: "Phone no",
    title: "Title",
    category: "Category",
    no_of_entries: "No. of Entries",
    location: "Location",
    start_time: "Start Time",
    end_time: "End Time",
    desc: "Description",
    list: "Challenge List",
    edit: "Edit Challenge",
    upload: "Upload Image or Video",
    allowed_entries: "Allowed Entries",
    select_category: "Select Category",
    start_date: "Start Date",
    end_date: "End Date",
  },
  manage_word: {
    manage_word: "Manage Words",
    add: "Add Word",
    name_eng: "Name (English)",
    name_arb: "Name (Arabic)",
    edit: "Edit Word",
  },

  manage_payments: {
    manage_payments: "Manage Payments",
    ads: "Ads",
    subscription: "Subscription Plan",
  },
  manage_gifts: {
    manage_gifts: "Manage Gifts",
    add: "Add Gift",
    edit: "Edit Gift",
    not_found: "Not Found",
    image: "Image",
    title_eng: "Title (English)",
    title_arb: "Title (Arabic)",
    gift_worth: "Gift Worth",
  },

  manage_notifications: {
    manage_notifications: "Manage Notifications",
    add: "Add Notification",
    not_found: "No Notifications Found",
    notification_title_eng: "Notification Title (English)",
    notification_title_arb: "Notification Title (Arabic)",
    notification_msg_eng: "Notification Message (English)",
    notification_msg_arb: "Notification Message (Arabic)",
    receiver: "Select Receiver",
    type: "Notification Type",
    both: "Both",
    email: "Email",
    select: "Select",
    notification: "Notification",
    send_all: "Send to All",
  },

  analytics: {
    report_analytics: "Reports and Aanlytics",
    users: "Users",
    advertisers: "Advertisers",
    earnings: "Earnings",
    transactions: "Transactions",
  },
  sub_admin: {
    manage_subAdmin: "Manage Sub-Admin",
    add: "Add Sub-Admin",
    edit: "Edit Sub-Admin",
    not_found: "No Sub-Admin Found",
    image: "Image",
    fname: "First Name",
    lname: "Last Name",
    email: "Email",
    phone: "Phone Number",
    module: "Module",
    add_edit: "Add/Edit",
    view: "View",
    delete: "Delete",
    dashboard: "Dashboard",
    manage_users: "Manage Users",
    manage_advertisers: "Manage Advertisers",
    manage_advertisements: "Manage Advertisements",
    manage_content: "Manage Content/Posts",
    manage_journey: "Manage Journey",
    challenge_categories: "Manage Challenge Categories",
    subscription_plan: "Manage Subscription Plans",
    manage_rewards: "Manage Rewards",
    customer_support: "Customer Support",
    manage_challenge: "Manage Challenges",
    manage_word: "Manage Words",
    manage_payment: "Manage Payments",
    manage_notification: "Manage Notifications",
    report_analytics: "Reports and Analytics",
    manage_cms: "Manage CMS",
    manage_faq: "Manage FAQs",
  },

  manage_cms: {
    manage_cms: "Manage CMS",
    contact_support: "Contact Support",
    about_us: "About Us",
    privacy: "Privacy Policy",
    term_condition: "Terms and Conditions",
    term_condition_eng: "Terms and Conditions (English)",
    term_condition_arb: "Terms and Conditions (Arabic)",
    email: "Email",
    phone: "Phone Number",
    about_us_eng: "About Us (English)",
    about_us_arb: "About Us (Arabic)",
    privacy_eng: "Privacy Policy (English)",
    privacy_arb: "Privacy Policy (Arabic)",
    support_en: "Support (English)",
    support_ar: "Support (Arabic)",
    support: "Support",
    show_subscription: "Show Subscription",
  },

  faq: {
    manage_faq: "Manage Faqs",
    ques_eng: "Question (English)",
    ques_arb: "Question (Arabic)",
    ans_eng: "Answer (English)",
    ans_arb: "Answer (Arabic)",
    add: "Add",
    remove: "Remove",
  },
};
