import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetAdvertiserGraphQuery, useLazyGetUserGraphQuery } from "../../services/dashboard";
import { showError } from "../../constants";
import useTranslation from "../../hooks/Translation";

const Analytics = () => {
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues,setUserValues]= useState<number[]>([])
  const [userFilter, setUserFilter] = useState<string>("Monthly");

  const [advertiserNames, setAdvertiserNames] = useState<string[]>([]);
  const [advertiserValues,setAdvertiserValues]= useState<number[]>([])
  const [advertiserFilter, setAdvertiserFilter] = useState<string>("Monthly");

  const [getUserGraphData, ] = useLazyGetUserGraphQuery();
  const [getAdvertiserGraphData, ] = useLazyGetAdvertiserGraphQuery();


  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleAdvertiserChange = (event: SelectChangeEvent) => {
    setAdvertiserFilter(event.target.value as string);
  };

  const getUserGraph = async () => {
    try {
      const response = await getUserGraphData({type:userFilter}).unwrap();
      if (response?.statusCode === 200) {
        setUserNames(
          response?.data?.map((item: { name: any }) => item?.name)
        );
        setUserValues(
          response?.data?.map((item: { value: any }) => item?.value)
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

   const getAdvertiserGraph = async () => {
    try {
      const response = await getAdvertiserGraphData({type:advertiserFilter}).unwrap();
      if (response?.statusCode === 200) {
        setAdvertiserNames(
          response?.data?.map((item: { name: any }) => item?.name)
        );
        setAdvertiserValues(
          response?.data?.map((item: { value: any }) => item?.value)
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(()=>{
    getUserGraph();
  },[userFilter])

  useEffect(()=>{
    getAdvertiserGraph();
  },[advertiserFilter])
  const translations=useTranslation()as any
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label:  translations.analytics.earnings||"",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphUsersData = {
    labels:userNames,
    datasets: [
      {
        label: translations.analytics.users||"",
        data:userValues,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphAdvertisersData = {
    labels:advertiserNames,
    datasets: [
      {
        label:  translations.analytics.advertisers||"",
        data:advertiserValues,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphTransactionsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Transactions",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">{translations.analytics.report_analytics||""}</h1>
            <Box className="cards_header_right">
            
            </Box>
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              {translations.analytics.users||""}
                <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleUserChange}
                >
                  <MenuItem value="" disabled>
                  {translations.graph_menu.select||""}
                  </MenuItem>
                  <MenuItem value="Daily">{translations.graph_menu.daily||""}</MenuItem>
                  <MenuItem value="Weekly">{translations.graph_menu.weekly||""}</MenuItem>
                  <MenuItem value="Monthly">{translations.graph_menu.monthly||""}</MenuItem>
                  <MenuItem value="Yearly">{translations.graph_menu.yearly||""}</MenuItem>
                </Select>
              </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              {translations.analytics.advertisers||""}
                <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={advertiserFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleAdvertiserChange}
                >
                 <MenuItem value="" disabled>
                  {translations.graph_menu.select||""}
                  </MenuItem>
                  <MenuItem value="Daily">{translations.graph_menu.daily||""}</MenuItem>
                  <MenuItem value="Weekly">{translations.graph_menu.weekly||""}</MenuItem>
                  <MenuItem value="Monthly">{translations.graph_menu.monthly||""}</MenuItem>
                  <MenuItem value="Yearly">{translations.graph_menu.yearly||""}</MenuItem>
                </Select>
              </FormControl>
              </h2>
              <LineChart data={graphAdvertisersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              {translations.analytics.earnings||""}
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>{translations.graph_menu.select||""}</option>
                    <option value={10}>{translations.graph_menu.day||""}</option>
                    <option value={20}>{translations.graph_menu.week||""}</option>
                    <option value={30}>{translations.graph_menu.monthly||""}</option>
                    <option value={40}>{translations.graph_menu.yearly||""}</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
              {translations.analytics.transactions||""}
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>{translations.graph_menu.select||""}</option>
                    <option value={10}>{translations.graph_menu.day||""}</option>
                    <option value={20}>{translations.graph_menu.week||""}</option>
                    <option value={30}>{translations.graph_menu.monthly||""}</option>
                    <option value={40}>{translations.graph_menu.yearly||""}</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
