// Staging Url ----
export const API_URL =
  "https://apistagebucket.appgrowthcompany.com/api/v1/admin";
// export const API_URL = "https://testapi.ambitio.ae/api/v1/admin";

// live url
// export const API_URL = "https://api.ambitio.ae/api/v1/admin";

// Development url
// export const API_URL = "https://apibucket.appgrowthcompany.com/api/v1/admin";

export const END_POINTS = {
  // auth
  login: "/login",
  forgotPassword: "/forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  changePassword: "/changePassword",
  get_user: "/profile",
  update_profile: "/updateProfile",
  mediaUpload: "upload",
  logout: "/logout",

  //user
  users: "/users",
  userStatusChange: "/userStatusChange",
  exportUsers: "/exportUsers",
  postByUserId: "/postByUserId",
  storyByUserId: "/story",
  postComments: "postComments",
  reportComments: "reportComments",
  comment: "comment",
  story: "story",

  //advertisers
  advertiser: "advertiser",
  advertiserStatusChange: "advertiserStatusChange",
  advertiserRequest: "advertiserRequest",
  exportAdvertiser: "exportAdvertiser",

  //Cms
  cms: "cms",
  faq: "faq",

  // post
  post: "post",
  postStatusChange: "postStatusChange",
  reportPost: "reportPost",

  // word
  word: "word",
  wordStatusChange: "wordStatusChange",

  // category
  category: "category",
  categoryStatusChange: "categoryStatusChange",
  subCategory: "subCategory",

  //dashboard
  dashboard: "dashboard",
  userGraph: "userGraph",
  advertiserGraph: "advertiserGraph",

  //subadmin
  subAdmin: "/subAdmin",

  getAdvertisement: "getAdvertisement",
  blockUnblockAdvertisement: "blockUnblockAdvertisement",
  deleteAdvertisement: "deleteAdvertisement",
  advertisementRequest: "advertisementRequest",
  subAdminStatusChange: "subAdminStatusChange",

  //customer Support
  getComplaints: "getComplaints",
  deleteComplaints: "deleteComplaints",
  getComplaintsById: "getComplaintsById",
  replyComplaints: "replyComplaints",
  getQueries: "getQueries",
  getQueriesById: "getQueriesById",
  replyQueries: "replyQueries",
  deleteQueries: "deleteQueries",

  //journey
  journey: "journey",
  journeyStatusChange: "journeyStatusChange",
  journeyComments: "journeyComments",
  reportCommentsJourney: "reportCommentsJourney",
  reportJourney: "reportJourney",

  rewards: "rewards",
  rewardsStatusChange: "rewardsStatusChange",

  //challenges
  getChallenges: "getChallenges",
  getChallengesById: "getChallengesById",
  deleteChallenge: "deleteChallenge",
  challengeStatusChange: "challengeStatusChange",
  getChallengeByUserId: "getChallengeByUserId",
  updateChallenge: "updateChallenge",
  challengePoints: "challengePoints",
  challenge: "challenge",
  notification: "notification",

  // users:"users"

  //gifts
  getGift: "getGift",
  deleteGift: "deleteGift",
  updateGift: "updateGift",
  addGift: "addGift",
  getGiftById: "getGiftById",
  giftStatusChange: "giftStatusChange",

  addSubscriptionPlan: "addSubscriptionPlan",
  getPlans: "getPlans",
  deletePlan: "deletePlan",
  planStatusChange: "planStatusChange",
  updatePlans: "updatePlans",

  // payment
  adpaymentHistory: "adpaymentHistory",

  subscriptionPaymentHistory: "subscriptionPaymentHistory",

  notificationListing: "notificationListing",
  liveStreamByUserId: "/liveStreamByUserId",
};
