import { Box, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction } from "react";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  image: string | undefined;
  type: string | undefined;
};

const DocumentViewer = ({ open, setOpen, image, type }: props) => {
  return (
    <Modal className="modal docs_modal" open={open} closeAfterTransition>
      <Box className="modalBox_body">
        <Box>
          <Button className="close_btn" onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>

          {type === "IMAGE" ? (
            <figure style={{ height: "100%", width: "80%", margin: "0 auto" }}>
              <img
                src={image || "/static/images/placeholder_doc.jpeg"}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </figure>
          ) : (
            <video
              autoPlay
              // muted
              loop
              playsInline
              // className="hm_vd"
              preload="yes"
              style={{
                height: "100%",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <source src={image || ""} type="video/mp4" />
            </video>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentViewer;
