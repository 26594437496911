import { END_POINTS } from "../constants/url";
import { GiftsResponse, adsHistoryResponse, subscriptionResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit: number;
  page: number;
  search: string;
  type?: string;
};

type GetAllGiftsResponse = {
  Gifts: GiftsResponse[];
  count: number;
};
type AddGiftBody = {
  title: string;
  title_ar: string;
  image: string;
  cost: number;
};

export const giftsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getGifts: builder.query<
      CommonResponseType & { data: GetAllGiftsResponse },
      GetTokenParams
    >({
      query: ({ page, limit, search }) => ({
        url: `${END_POINTS.getGift}?search=${search}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    getGiftById: builder.query<
      CommonResponseType & { data: GiftsResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getGiftById}/${id}`,
        method: "GET",
      }),
    }),
    changeGiftStatus: builder.query<
      CommonResponseType & { data: GiftsResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.giftStatusChange}/${id}`,
        method: "PATCH",
      }),
    }),

    deleteGiftById: builder.query<
      CommonResponseType & { data: GiftsResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteGift}/${id}`,
        method: "DELETE",
      }),
    }),
    postGift: builder.mutation<CommonResponseType, AddGiftBody>({
      query: (body) => ({
        url: `${END_POINTS.addGift}`,
        method: "POST",
        body,
      }),
    }),
    editGiftById: builder.mutation<
      CommonResponseType & { data: GiftsResponse },
      { id: string; body: AddGiftBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateGift}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getPaymentHistory: builder.query<
      CommonResponseType & { data: adsHistoryResponse },
      GetTokenParams
    >({
      query: ({page, limit, search }) => ({
        url: `${END_POINTS.adpaymentHistory}?search=${search}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getSubscriptionPaymentHistory: builder.query<
      CommonResponseType & { data: subscriptionResponse },
      GetTokenParams
    >({
      query: ({page, limit, search }) => ({
        url: `${END_POINTS.subscriptionPaymentHistory}?search=${search}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetGiftsQuery,
  useLazyDeleteGiftByIdQuery,
  useLazyChangeGiftStatusQuery,
  usePostGiftMutation,
  useEditGiftByIdMutation,
  useLazyGetGiftByIdQuery,
  useLazyGetPaymentHistoryQuery,
  useLazyGetSubscriptionPaymentHistoryQuery
} = giftsApi;
