import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StoryResponse } from "../../types/General";

import { Pagination } from "../../components";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyDeleteUserStoriesQuery } from "../../services/user";
import WarnModal from "../../components/WarnModal";
import { useState, useEffect } from "react";
import { handleDelete, handleDeleteStories } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";

type props = {
  page: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  userStories: StoryResponse[];
  getUserStories: (id: string) => void;
};

const UserStories = ({
  userStories,
  page,
  totalPages,
  onPageChange,
  getUserStories,
}: props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteById] = useLazyDeleteUserStoriesQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [selectId, setSelectedId] = useState<string>("");

  useEffect(() => {
    if (id) {
      getUserStories(id); // Call getStories function with the user id
    }
  }, [id]);
  const translation = useTranslation() as any;
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {translation.table_heads.sno || ""}
              </TableCell>
              <TableCell>{translation.table_heads.img_vid || ""} </TableCell>
              <TableCell>{translation.table_heads.text || ""} </TableCell>
              <TableCell>{translation.table_heads.duration || ""} </TableCell>
              <TableCell>{translation.table_heads.date_time || ""} </TableCell>
              <TableCell>{translation.table_heads.actions || ""} </TableCell>
            </TableRow>
          </TableHead>

          {userStories?.length ? (
            userStories?.map((item, i) => {
              return (
                <TableBody key={item?._id}>
                  <TableRow>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>
                      {item?.type === "IMAGE" ? (
                        <figure className="bnr_img">
                          <img
                            src={item?.uploads || "/static/images/post.png"}
                            alt=""
                          />
                        </figure>
                      ) : (
                        <figure className="bnr_img">
                          <img
                            src={item?.thumbnail || "/static/images/post.png"}
                            alt=""
                          />
                        </figure>
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.text?.length > 30
                        ? item?.text.slice(0, 30) + "..."
                        : item?.text || "-"}
                    </TableCell>
                    <TableCell>{item?.duration || "0"} s</TableCell>

                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        {moment(item?.createdAt).format("DD/MM/YYYY")}
                      </Typography>

                      <Typography variant="h6" fontSize={14}>
                        {moment(item?.createdAt).format("h:mm A")}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/story-details/${item?._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          // onClick={()=>{
                          //   setOpen(true)
                          //   setSelectedId(item?._id)
                          // }}
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(item?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                {translation.Globals.no_stories}
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        module={userStories}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="Story"
        handleDelete={() => {
          // handleDelete()
          // handleDeleteStories(deleteById,selectId,id,()=>{
          //   getUserStories(id)
          // })
          handleDelete(deleteById, selectId, () => {
            getUserStories(id || " ");
          });
        }}
      />
    </Card>
  );
};

export default UserStories;
