import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { FormControl, Grid, MenuItem, Paper, Select, SelectChangeEvent } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { useNavigate } from "react-router-dom";
import { useLazyGetAdvertiserGraphQuery, useLazyGetDashboardQuery, useLazyGetUserGraphQuery } from "../../services/dashboard";
import { DashboardData } from "../../types/General";
import { Loader, showError } from "../../constants";
import useTranslation from "../../hooks/Translation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const translation= useTranslation() as any;
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues,setUserValues]= useState<number[]>([])
  const [userFilter, setUserFilter] = useState<string>("Monthly");
  const [earningFilter, setEarningFilter] = useState<string>("Monthly");

  

  

  const [getDashBoardData, { isLoading }] = useLazyGetDashboardQuery();
  const [getUserGraphData, ] = useLazyGetUserGraphQuery();
  const [getAdvertiserGraphData, ] = useLazyGetAdvertiserGraphQuery();

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  const getDashBoard = async () => {
    try {
      const response = await getDashBoardData({}).unwrap();
      if (response?.statusCode === 200) {
        setDashboardData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getUserGraph = async () => {
    try {
      const response = await getUserGraphData({type:userFilter}).unwrap();
      if (response?.statusCode === 200) {
        setUserNames(
          response?.data?.map((item: { name: any }) => item?.name)
        );
        setUserValues(
          response?.data?.map((item: { value: any }) => item?.value)
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  // const getAdvertiserGraph = async () => {
  //   try {
  //     const response = await getAdvertiserGraphData({type:advertiserFilter}).unwrap();
  //     if (response?.statusCode === 200) {
  //       setAdvertiserData(response?.data);
  //     }
  //   } catch (error: any) {
  //     showError(error?.data?.message || "");
  //   }
  // };

  useEffect(() => {
    getDashBoard();
   
    // getAdvertiserGraph()
  }, []);
  useEffect(()=>{
    getUserGraph();
  },[userFilter])

  const graphUserData = {
    labels: userNames,
    datasets: [
      {
        label: translation.graph_menu.users,
        data:userValues,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: translation.graph_menu.earning,
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };
  const graphDownloadsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Downloads",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1 className="mn_hdng">{translation.dashboard.dashboard || ""}</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.total_users || ""}</h3>
              <h4 className="mn_hdng">{dashboardData?.totalUser || ""}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-content")}
          >
            <PanoramaIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.total_post || ""}</h3>
              <h4 className="mn_hdng">{dashboardData?.totalPost || ""}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-challenges")}
          >
            <ThumbUpAltIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.total_chlg || ""}</h3>
              <h4 className="mn_hdng">{dashboardData?.totalChalleges || ""}</h4>
            </div>
          </Item>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Total Booking Requests</h3>
              <h4 className="mn_hdng">10</h4>
            </div>
          </Item>
        </Grid> */}
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-journeys")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.total_journey || ""}</h3>
              <h4 className="mn_hdng">{dashboardData?.totalJourney || ""}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">{translation.dashboard.total_users || ""}
            <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleUserChange}
                >
                  <MenuItem value="" disabled>
                    {translation.graph_menu.select||""}
                  </MenuItem>
                  <MenuItem value="Daily">{translation.graph_menu.daily||""}</MenuItem>
                  <MenuItem value="Weekly">{translation.graph_menu.weekly||""}</MenuItem>
                  <MenuItem value="Monthly">{translation.graph_menu.monthly||""}</MenuItem>
                  <MenuItem value="Yearly">{translation.graph_menu.yearly||""}</MenuItem>
                </Select>
              </FormControl></h2>
            <LineChart data={graphUserData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">{translation.dashboard.total_earning || ""}
            <FormControl>
            
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleEarningChange}
                >
                   <MenuItem value="" disabled>
                    {translation.graph_menu.select||""}
                  </MenuItem>
                  <MenuItem value="Daily">{translation.graph_menu.daily||""}</MenuItem>
                  <MenuItem value="Weekly">{translation.graph_menu.weekly||""}</MenuItem>
                  <MenuItem value="Monthly">{translation.graph_menu.monthly||""}</MenuItem>
                  <MenuItem value="Yearly">{translation.graph_menu.yearly||""}</MenuItem>
                </Select>
              </FormControl></h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Downloads</h2>
            <LineChart data={graphDownloadsData} />
          </Item>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DashBoard;
