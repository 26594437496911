import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { useLazyGetSubscriptionPaymentHistoryQuery } from "../../services/gifts";
import { showError } from "../../constants";
import { subscription } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";

const SubscriptionPayments = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [payment, setPayment] = useState<subscription[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [paymentHistory, { isLoading }] =
    useLazyGetSubscriptionPaymentHistoryQuery();

  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];
  const fetchPaymentHistory = async () => {
    try {
      const response = await paymentHistory({
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();

      if (response?.statusCode === 200) {
        // setAds(response?.data?.data)
        setPayment(response?.data?.data || []);
        setTotalCount(response?.data?.total);
      }

      // if (response?.statusCode === 200) {
      //   setPaymentList(response?.data?. || []);
      //   setTotalCount(response?.data?.count);
      // } else {
      //   setPaymentList([]);
      // }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const translations = useTranslation() as any;
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  useEffect(() => {
    fetchPaymentHistory();
  }, [searchTerm, debouncedSearchTerm, page]);
  return (
    <div>
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }
          }
        />
      </Box>
      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {translations.table_heads.sno || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.username || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.email || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.phone_number || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.payment_received || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.payment_mode || ""}
              </TableCell>
              {/* <TableCell align="center">
                {translations.table_heads.actions || ""}
              </TableCell> */}
            </TableRow>
          </TableHead>

          {payment?.length ? (
            payment?.map((row, index) => (
              <TableBody>
                <TableRow key={row?._id}>
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>

                  <TableCell align="center">{row?.userName || ""}</TableCell>
                  <TableCell align="center">{row?.email || ""}</TableCell>

                  <TableCell align="center">{row?.phone||""}</TableCell>
                  <TableCell align="center">AED {row?.amount||""}</TableCell>
                  <TableCell align="center">{row?.transactionType||""}</TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                {translations.Globals.no_subs}
              </TableCell>
            </TableBody>
          )}
          
        </Table>
      </TableContainer>
      <Pagination
      module={payment}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalPages}/>
    </div>
  );
};

export default SubscriptionPayments;
