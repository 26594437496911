import { END_POINTS } from "../constants/url";
import {
  PostResponse,
  ReportedPosts,
  StoryResponse,
  UserResponse,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
};

type GetAllUsersResponse = {
  user: UserResponse[];
  count: number;
};

type EditUserById = {
  image: string;
  email: string;
  fullName: string;
  countryCode: string;
  phone: string;
};
type GetAllPostsResponse = {
  post: PostResponse[];
  count: number;
};
type GetAllReportedPostsResponse = {
  reportPost: ReportedPosts[];
  count: number;
};
type GetAllStoriesResponse = {
  story: StoryResponse[];
  storyCount: number;
};

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      CommonResponseType & { data: GetAllUsersResponse },
      GetTokenParams
    >({
      query: ({ page, limit, query }) => ({
        url: `${END_POINTS.users}?page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),
    getUserById: builder.query<
      CommonResponseType & { data: UserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.users}/${id}`,
        method: "GET",
      }),
    }),
    changeUserStatus: builder.query<
      CommonResponseType & { data: UserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.userStatusChange}/${id}`,
        method: "GET",
      }),
    }),
    editUserById: builder.mutation<
      CommonResponseType & { data: UserResponse },
      { id: string; body: EditUserById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.users}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteUserById: builder.query<
      CommonResponseType & { data: UserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.users}/${id}`,
        method: "DELETE",
      }),
    }),
    getUserCSV: builder.query<
      CommonResponseType & { data: string },
      {
        search: string;
      }
    >({
      query: ({ search }) => ({
        url: `${END_POINTS.exportUsers}?search=${search}`,
        method: "GET",
      }),
    }),
    getUserPosts: builder.query<
      CommonResponseType & { data: GetAllPostsResponse },
      { id: string; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.postByUserId}/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getUserReportedPosts: builder.query<
      CommonResponseType & { data: GetAllReportedPostsResponse },
      { id: string; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.reportPost}?userId=${id}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getUserStories: builder.query<
      CommonResponseType & { data: GetAllStoriesResponse },
      { id: string; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.storyByUserId}?userId=${id}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    deleteUserStories: builder.query<
      CommonResponseType & { data: GetAllStoriesResponse },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.story}/${id}`,
        method: "DELETE",
      }),
    }),
    getUserLiveStreaming: builder.query<
      CommonResponseType & { data: any },
      { id: string; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.liveStreamByUserId}/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useLazyGetUserByIdQuery,
  useLazyChangeUserStatusQuery,
  useLazyDeleteUserByIdQuery,
  useEditUserByIdMutation,
  useLazyGetUserCSVQuery,
  useLazyGetUserPostsQuery,
  useLazyGetUserStoriesQuery,
  useLazyGetUserReportedPostsQuery,
  useLazyDeleteUserStoriesQuery,
  useLazyGetUserLiveStreamingQuery,
} = userApi;
