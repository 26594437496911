import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useLazyGetCmsQuery, useUpdateCmsMutation } from "../../services/cms";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader, showError, showToast } from "../../constants";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState<string>("");
  const translations = useTranslation() as any;
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [termsAndConditionsAr, setTermsAndConditionsAr] = useState<string>("");
  const [checked, setChecked] = useState(false);

  const [aboutUs, setAboutUs] = useState<string>("");
  const [aboutUsAr, setAboutUsAr] = useState<string>("");

  const [support, setSupport] = useState<string>("");
  const [supportAr, setSupportAr] = useState<string>("");

  const [value, setValue] = React.useState(0);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [cmsId, setCmsId] = useState("");

  const [cmsDetails, getCmaDetailData] = useLazyGetCmsQuery();
  const [updateCms, { isLoading }] = useUpdateCmsMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  console.log(termsAndConditions, "lenght");
  console.log(aboutUsAr, "asasas");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        termsAndConditions: termsAndConditions,
        termsAndConditions_ar: termsAndConditionsAr,
        privacyPolicy: privacyPolicy,
        privacyPolicy_ar: privacyPolicyAr,
        aboutUs: aboutUs,
        aboutUs_ar: aboutUsAr,
        support: support,
        support_ar: supportAr,
        showSubscription: checked,
        appKey: new Date().toISOString(),
      };

      if (aboutUs?.length <= 8) {
        return showError("About us is empty");
      }
      if (aboutUsAr?.length <= 8) {
        return showError("About us arabic is empty");
      }
      if (termsAndConditions?.length <= 8) {
        return showError("Terms and conditions is empty");
      }

      if (termsAndConditionsAr?.length <= 8) {
        return showError("Terms and conditions arabic  is empty");
      }
      if (privacyPolicy?.length <= 8) {
        return showError("Privacy policy is empty");
      }

      if (privacyPolicyAr?.length <= 8) {
        return showError("Privacy policy arabic is empty");
      }

      if (support?.length <= 8) {
        return showError("Support is empty");
      }

      if (supportAr?.length <= 8) {
        return showError("Support arabic is empty");
      }

      try {
        const response = await updateCms({ body, cms_id: cmsId }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "CMS updated successfully!");
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
      }
    },
  });

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();
      if (res?.statusCode === 200 && res?.data?.length) {
        formik.setFieldValue("email", res?.data[0]?.email);
        formik.setFieldValue("phone", res?.data[0]?.phone);

        setAboutUs(res?.data[0]?.aboutUs || " ");
        setAboutUsAr(res?.data[0]?.aboutUs_ar || " ");

        setPrivacyPolicy(res?.data[0]?.privacyPolicy || " ");
        setPrivacyPolicyAr(res?.data[0]?.privacyPolicy_ar || " ");

        setTermsAndConditions(res?.data[0]?.termsAndConditions || " ");
        setTermsAndConditionsAr(res?.data[0]?.termsAndConditions_ar || " ");

        setSupport(res?.data[0]?.support || " ");
        setSupportAr(res?.data[0]?.support_ar || " ");

        setPhoneCode(res?.data[0]?.countryCode || "");
        setCmsId(res?.data[0]?._id || "");
        setChecked(res?.data[0]?.showSubscription || false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || getCmaDetailData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_cms.manage_cms || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={translations.manage_cms.contact_support || ""}
                {...a11yProps(0)}
              />
              <Tab
                label={translations.manage_cms.about_us || ""}
                {...a11yProps(1)}
              />
              <Tab
                label={translations.manage_cms.privacy || ""}
                {...a11yProps(2)}
              />
              <Tab
                label={translations.manage_cms.term_condition || ""}
                {...a11yProps(3)}
              />
              <Tab
                label={translations.manage_cms.support || ""}
                {...a11yProps(4)}
              />
              <Tab
                label={translations.manage_cms.show_subscription || ""}
                {...a11yProps(5)}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_cms.email || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder={translations.manage_cms.email || ""}
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 40 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_cms.phone || ""}
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translations.manage_cms.phone || ""}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translations.manage_cms.about_us_eng || ""}</h3>
                <EditText content={aboutUs} setContent={setAboutUs} />
                <h3>{translations.manage_cms.about_us_arb || ""}</h3>
                <EditText content={aboutUsAr} setContent={setAboutUsAr} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translations.manage_cms.privacy_eng || ""}</h3>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <h3>{translations.manage_cms.privacy_arb || ""}</h3>
                <EditText
                  content={privacyPolicyAr}
                  setContent={setPrivacyPolicyAr}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translations.manage_cms.term_condition_eng || ""}</h3>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <h3>{translations.manage_cms.term_condition_arb || ""}</h3>
                <EditText
                  content={termsAndConditionsAr}
                  setContent={setTermsAndConditionsAr}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translations.manage_cms.support_en || ""}</h3>
                <EditText content={support} setContent={setSupport} />
                <h3>{translations.manage_cms.support_ar || ""}</h3>
                <EditText content={supportAr} setContent={setSupportAr} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <form onSubmit={formik.handleSubmit}>
                <Box
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <h4>{translations.manage_cms.show_subscription || ""}:</h4>
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.save || ""}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
