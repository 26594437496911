import {
  Box,
  Card,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StoryResponse } from "../../types/General";

import { Pagination } from "../../components";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyDeleteUserStoriesQuery } from "../../services/user";
import WarnModal from "../../components/WarnModal";
import { useState, useEffect } from "react";
import { handleDelete, handleDeleteStories } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";

type props = {
  page: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  userStories: any;
};

const LiveStreaming = ({
  userStories,
  page,
  totalPages,
  onPageChange,
}: props) => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const translation = useTranslation() as any;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ mt: 4 }} className="cards">
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {translation.table_heads.sno || ""}
              </TableCell>
              <TableCell>{translation.table_heads.title || ""} </TableCell>
              <TableCell>{translation.table_heads.date || ""} </TableCell>
              <TableCell>
                {translation.manage_challenge.start_time || ""}{" "}
              </TableCell>
              <TableCell>
                {translation.manage_challenge.end_time || ""}{" "}
              </TableCell>
              <TableCell>
                {translation.Globals.comments_enabled || ""}{" "}
              </TableCell>
              <TableCell>{translation.Globals.noOfUsers || ""} </TableCell>
              <TableCell>{translation.table_heads.actions || ""} </TableCell>
            </TableRow>
          </TableHead>

          {userStories?.length ? (
            userStories?.map((item: any, i: number) => {
              return (
                <TableBody key={item?._id}>
                  <TableRow>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>{item?.title || ""}</TableCell>
                    <TableCell>
                      {item?.createdAt
                        ? moment(item?.createdAt).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {item?.startTime
                        ? moment(item?.startTime).format("h:mm A")
                        : "-"}
                    </TableCell>

                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        {item?.endTime
                          ? moment(item?.endTime).format("h:mm A")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography variant="h6" fontSize={14}>
                        {item?.isCommentEnable ? "Yes" : "No"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Typography variant="h6" fontSize={14}>
                        {item?.streamMemberCount || 0}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => {
                            setUsers(item?.streamMember);
                            setOpen(true);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                {translation.Globals.no_live_history}
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            padding: "20px",
            maxHeight: "80%",
            overflowY: "auto",
            minWidth: "60%",
            maxWidth: "60%",
          }}
        >
          <Typography variant="h6">Joined Users</Typography>
          <ul
            className="cmnt_rplies"
            style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
          >
            {users?.length ? (
              users?.map((item: any) => {
                return (
                  <li
                    className="rply"
                    key={item?.userId}
                    style={{ marginBottom: 0, width: "44%" }}
                  >
                    <div className="user_dtls">
                      <figure>
                        <img
                          src={
                            item?.image || "/static/images/user_placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div className="userss">
                        <h2>Username: {item?.userName || "-"}</h2>
                        <h2>Email: {item?.email || "-"}</h2>
                        <h2>
                          Phone: {item?.countryCode} {item?.phone}
                        </h2>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div>
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  No Users Found
                </h2>
              </div>
            )}
          </ul>
        </Paper>
      </Modal>
      <Pagination
        module={userStories}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </Card>
  );
};

export default LiveStreaming;
