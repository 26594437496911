import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import { useAddRewardsMutation, useLazyGetRewardsQuery, useUpdateRewardsMutation } from "../../services/advertiser";
import { showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { RewardsResponse } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";

const AddRewards = () => {
  const navigate = useNavigate();
  const [getRewards, { isLoading }] = useLazyGetRewardsQuery();
  const [updateRewards]=useUpdateRewardsMutation()

  const {id}=useParams()
  const [rewards, setRewards] = useState<RewardsResponse[]>([]);

  const [category, setCategory] = useState<string>("");
  
const [postDetails,setPostDetails]=useState<any>({
  title:"",
  title_ar:"",
  noOfJourney:0,
  noOfPosts:0,
  noOfChallenges:0,
  pointsEarned:0
})
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [addRewards]=useAddRewardsMutation()
  const [error, setError] = useState<boolean>(false);
  const translations=useTranslation()as any
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  console.log(rewards,"rrrrrrrr")

  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
      title:rewards[0]?.title||"",
      title_ar:rewards[0]?.title_ar||"",
      noOfJourneys:rewards[0]?.noOfJourneys||"",
      noOfPosts:rewards[0]?.noOfPosts||"",
      noOfChallenges:rewards[0]?.noOfChallenges||"",
      pointsEarned:rewards[0]?.pointsEarned||"",

    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
      title_ar: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
      pointsEarned: Yup.string()
        .required(translations.Globals.field_req)
        .min(1, translations.Globals.min_1)
        .max(12, translations.Globals.max_12),
      noOfJourneys: Yup.string()
            .required(translations.Globals.field_req)
            .min(1, translations.Globals.min_1)
            .max(12, translations.Globals.max_12),
      noOfChallenges: Yup.string()
            .required(translations.Globals.field_req)
            .min(1, translations.Globals.min_1)
            .max(12, translations.Globals.max_12),
       noOfPosts: Yup.string()
            .required(translations.Globals.field_req)
            .min(1, translations.Globals.min_1)
            .max(12, translations.Globals.max_12),
    }),
    

    onSubmit:async(values)=>{
      formik.setSubmitting(true);
      const body={
        title: rewards[0]?.title!==values.title?values.title:rewards[0]?.title,
        title_ar:rewards[0]?.title_ar!==values.title_ar?values.title_ar:rewards[0]?.title_ar,
        image:image,
        noOfJourneys:rewards[0]?.noOfJourneys!==values.noOfJourneys?values.noOfJourneys:rewards[0]?.noOfJourneys,
        noOfPosts:rewards[0]?.noOfPosts!==values.noOfPosts?values.noOfPosts:rewards[0]?.noOfPosts,
        noOfChallenges:rewards[0]?.noOfChallenges!==values.noOfChallenges?values.noOfChallenges:rewards[0]?.noOfChallenges,
        pointsEarned:rewards[0]?.pointsEarned!==values.pointsEarned?values.pointsEarned:rewards[0]?.pointsEarned,
        appKey: new Date().toISOString(),
      }

      try{  
        if(id){
          console.log(body,"idbody")
          try{
            const resposne:any=await updateRewards({
              body,
              id
            }).unwrap()
            console.log(resposne,"res")
      
            if(resposne?.statusCode===200){
              showToast(translations.Globals.rewards_update)
              navigate('/manage-rewards')
            }
      
      
        }catch(error:any){
          showError(error?.data?.message || "")
        }
  
        }else{
  
          try{
          const resposne:any=await addRewards({
            body
          }).unwrap()
          console.log(resposne,"res")
    
          if(resposne?.statusCode===200){
            showToast(resposne?.message||"")
            navigate('/manage-rewards')
          }
    
    
      }catch(error:any){
        showError(error?.data?.message || "")
      }
        }

      }catch(error:any){
        showError(error?.data?.message||"")
      }
      


    }
  })


  const getRewardsDetails=async(id:string)=>{
    try {
      const response: any = await getRewards({
        id
      });
      console.log(response?.data?.data?.rewards, "response");
      if (response?.data?.statusCode === 200) {
        setRewards(response?.data?.data?.rewards);
        setImage(response?.data?.data?.rewards[0]?.image)
        
      } else {
        setRewards([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }


  const addBadge=async()=>{
    try{
        const body={
          title:postDetails.title,
          title_ar:postDetails.title_ar,
          image:image,
          noOfJourneys:postDetails.noOfJourney,
          noOfPosts:postDetails.noOfPosts,
          noOfChallenges:postDetails.noOfChallenges,
          pointsEarned:postDetails.pointsEarned,
          appKey: new Date().toISOString(),

        }

        console.log(body,"bodyyyyy")

        const resposne:any=await addRewards({
          body
        }).unwrap()
        console.log(resposne,"res")

        if(resposne?.statusCode===200){
          showToast(resposne?.message||"")
          navigate('/manage-rewards')
        }


    }catch(error:any){
      showError(error?.data?.message || "")
    }
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  useEffect(()=>{
    if(id){
      getRewardsDetails(id)
    }
  },[])

 
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{translations.manage_rewards.add||""}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-rewards");
              }}
            >
              {translations.Globals.back||""}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="custom_label">{translations.manage_rewards.image||""}</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">{translations.Globals.field_req}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translations.manage_rewards.title_eng||""}<span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                      placeholder={translations.manage_rewards.title_eng||""}
                      value={formik.values.title}
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                          
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.title && formik.errors.title}
                        </h6>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translations.manage_rewards.title_arb||""}<span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      fullWidth
                      placeholder= {translations.manage_rewards.title_arb||""}
                      inputProps={{ maxLength: 30 }}
                      value={formik.values.title_ar}
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                          
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.title_ar && formik.errors.title_ar}
                        </h6>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translations.manage_rewards.points_earned||""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="pointsEarned"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      placeholder= {translations.manage_rewards.points_earned||""}
                      onBlur={formik.handleBlur}
                      value={formik.values.pointsEarned}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.pointsEarned && formik.errors.pointsEarned}
                        </h6>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translations.manage_rewards.no_of_journey||""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="noOfJourneys"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      placeholder={translations.manage_rewards.no_of_journey||""}
                      // value={postDetails.noOfJourney}
                      onBlur={formik.handleBlur}
                      value={formik.values.noOfJourneys}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.pointsEarned && formik.errors.pointsEarned}
                        </h6>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translations.manage_rewards.no_of_post||""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="noOfPosts"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      placeholder={translations.manage_rewards.no_of_post||""}
                      value={formik.values.noOfPosts}
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.noOfPosts && formik.errors.noOfPosts}
                        </h6>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translations.manage_rewards.no_of_challenge||""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="noOfChallenges"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      placeholder= {translations.manage_rewards.no_of_challenge||""}
                      value={formik.values.noOfChallenges}
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        <h6 className="err_msg">

                          {formik.touched.noOfChallenges && formik.errors.noOfChallenges}
                        </h6>
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    {translations.Globals.save||""}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddRewards;
