import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { ChangeEvent, useEffect, useState } from "react";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as YUP from "yup";
import { Autocomplete } from "@react-google-maps/api";
import { isString } from "../../utils/validations";
import {
  useLazyGetCategoryByIdQuery,
  usePostJourneyMutation,
} from "../../services/advertiser";

const AddJourney = () => {
  const navigate = useNavigate();
  const translations = useTranslation() as any;
  const [type, setType] = useState("");
  const [media, setMedia] = useState<string>("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [cId, setCid] = useState<string>("");

  const [categories, setCategories] = useState<any>();
  const [category, setCategory] = useState("");
  const [getCategory] = useLazyGetCategoryByIdQuery();

  const [addJourney] = usePostJourneyMutation();

  const fetchCategory = async () => {
    try {
      const response: any = await getCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.categories);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }

          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        formik.setFieldValue("location", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("country", country || "");
      }
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : null;

    if (file) {
      event.target.value = "";

      if (file.type.startsWith("video/")) {
        const videoDuration = await getVideoDuration(file);

        if (videoDuration > 30) {
          showError(translations.Globals.video_too_long);
          return;
        }

        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
          setType("VIDEO");
          setMedia(res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        showError(translations.Globals.only_video);
      }
    } else {
      showError(translations.Globals.select_img);
    }
  };

  const getVideoDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error("Failed to load video"));
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleCancel = () => {
    setMedia("");
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
    const newItem = categories.find(
      (item: any) => item.name === event.target.value
    );

    setCid(newItem._id);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      targetDate: "",

      location: "",
      longitude: "",
      latitude: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },

    validationSchema: YUP.object({
      title: YUP.string().required(translations.Globals.field_req),
      description: YUP.string().required(translations.Globals.field_req),
      location: YUP.string().required(translations.Globals.field_req),
      targetDate: YUP.string().required(translations.Globals.field_req),
    }),

    onSubmit: async (values) => {
      if (!media) {
        showError(translations.Globals.upload_video);
        return;
      }
      if (!cId) {
        showError(translations.Globals.cat_req);
        return;
      }

      formik.setSubmitting(true);

      const body = {
        uploads: [
          {
            link: media,
            type: "VIDEO",
            height: 4,
            width: 4,
          },
        ],
        title: values.title,
        description: values.description,
        isPrivate: false,
        categoryId: cId,
        date: values.targetDate,
        completeAddress: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        city: values.city,
        country: values.country,
        zipCode: values.zipCode,
        state: values.state,
        appKey: new Date().toISOString(),
      };

      console.log(body, "body");

      try {
        const response: any = await addJourney(body).unwrap();
        if (response?.statusCode === 200) {
          showToast(translations.Globals.journey_created);
          navigate("/manage-journeys");
        }
      } catch (error: any) {
        showError(error?.data?.message);
      }
    },
  });

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.Globals.add_journey}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-journeys");
            }}
          >
            {translations.Globals.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography className="custom_label">
                    {translations.Globals.journey_video || ""}{" "}
                    <span className="asterisk">*</span>
                  </Typography>
                  {media ? (
                    <div className="upload_image_preview2">
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="video_dv"
                        preload="yes"
                      >
                        <source src={media} type="video/mp4" />
                      </video>

                      <CancelIcon onClick={handleCancel} />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "video/*",
                          }}
                          onChange={(e) => {
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>
                            );
                          }}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} />

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_challenge.title || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type={"text"}
                    name="title"
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    value={formik.values.title}
                    placeholder={translations.manage_challenge.title || ""}
                    onBlur={formik.handleBlur}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={formik.touched.title && formik.errors.title}
                  ></TextField>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_challenge.location || ""}{" "}
                    <span className="asterisk">*</span>
                  </Typography>

                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={() => onPlaceChanged()}
                  >
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      fullWidth
                      placeholder={translations.manage_challenge.location || ""}
                      name="location"
                      variant="outlined"
                      id="location"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                  </Autocomplete>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_challenge.select_category || ""}{" "}
                    <span className="asterisk">*</span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleCategoryChange}
                    >
                      <MenuItem value="" disabled>
                        {translations.Globals.select}
                      </MenuItem>

                      {categories?.map((item: any, index: any) => (
                        <MenuItem value={item?.name}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.Globals.targetDate || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type={"date"}
                    name="targetDate"
                    variant="outlined"
                    fullWidth
                    value={formik.values.targetDate}
                    placeholder={translations.manage_challenge.targetDate || ""}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    helperText={
                      formik.touched.targetDate && formik.errors.targetDate
                    }
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      max: "3000-12-31",
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_challenge.desc || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    variant="outlined"
                    className="text_field"
                    value={formik.values.description}
                    fullWidth
                    placeholder={translations.manage_challenge.desc || ""}
                    multiline
                    minRows={4}
                    maxRows={4}
                    inputProps={{ maxLength: 300 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.description &&
                          formik.errors.description}
                      </h6>
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  {translations.Globals.submit || ""}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddJourney;
