import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Input,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  IconButton,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { Autocomplete } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import * as YUP from "yup";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  useAddChallnegeMutation,
  useLazyGetCategoryByIdQuery,
  useLazyGetChallengeByIdQuery,
  useUpdateChallengeMutation,
} from "../../services/advertiser";
import { showError, showToast } from "../../constants";
import { Entry } from "../../types/General";
import moment from "moment";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";
import { log } from "console";

interface Option {
  id: number;
  value: string;
}

type ChallengeList = {
  id: string;
  name: string;
};
const AddChallenge = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const { id } = useParams();
  const [challenge, setChallenge] = useState<Entry>();
  const [cId, setCid] = useState<string>("");

  const [type, setType] = useState("");
  const [challengeList, setChallengeList] = useState<{ name: string }[]>([]);

  const [currentChallenge, setCurrentChallenge] = useState("");
  const [error, setError] = useState(false);

  const [list, setList] = useState<ChallengeList[]>([{ id: "", name: "" }]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [media, setMedia] = useState<string>("");
  const [categories, setCategories] = useState<any>();
  const translations = useTranslation() as any;
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      event.target.value = "";
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setType("IMAGE");
          setMedia(res?.data);
        } else {
          showError(res?.message);
        }
      } else if (file.type.startsWith("video/")) {
        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
          setType("VIDEO");
          setMedia(res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        showError(translations.Globals.select_img);
        // setLoading(false);
      }
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      console.log(place, "placde");

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }

          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        formik.setFieldValue("location", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("country", country || "");
      }
    }
  };

  const handleCancel = () => {
    setMedia("");
  };

  const [getChallenges, { isLoading }] = useLazyGetChallengeByIdQuery();
  const [getCategory] = useLazyGetCategoryByIdQuery();
  const [updateChallenge] = useUpdateChallengeMutation();
  const [addChallenge] = useAddChallnegeMutation();

  const fetchCategory = async () => {
    try {
      const response: any = await getCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.categories);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchChallenges = async (id: string | undefined) => {
    try {
      const response: any = await getChallenges({
        id: id,
      }).unwrap();
      console.log(response.data);
      if (response?.statusCode === 200) {
        setChallenge(response?.data);
        setCategory(response?.data?.categoryName);
        setMedia(response?.data?.uploads[0]?.link);
        setType(response?.data?.uploads[0]?.type);
        setList(response?.data?.challengeList);
        setCid(response?.data?.categoryId);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const handleAddChallenge = () => {
    if (currentChallenge.trim() === "") {
      setError(true);
    } else {
      setError(false);
      setChallengeList((prevChallenges) => [
        ...prevChallenges,
        { name: currentChallenge },
      ]);
      setCurrentChallenge("");
    }
  };

  const handleDeleteChallenge = (index: number) => {
    setChallengeList((prevChallenges) =>
      prevChallenges.filter((_, i) => i !== index)
    );
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
    const newItem = categories.find(
      (item: any) => item.name === event.target.value
    );

    setCid(newItem._id);
  };
  const prefilledEntries = challenge?.noOfEntries || 0;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: challenge?.title || "",
      description: challenge?.description || "",
      startDate:
        moment.utc(challenge?.startTime).format("YYYY-MM-DD HH:mm") ||
        moment(new Date()).format("YYYY-MM-DD HH:mm"),
      endDate:
        moment.utc(challenge?.endTime).format("YYYY-MM-DDTHH:mm") ||
        moment(new Date()).format("YYYY-MM-DD HH:mm"),
      entries: challenge?.noOfEntries || "",
      location: challenge?.completeAddress || "",
      longitude: challenge?.longitude || "",
      latitude: challenge?.latitude || "",
      city: challenge?.city || "",
      state: challenge?.state || "",
      country: challenge?.country || "",
      zipCode: challenge?.zipCode || "",
    },

    validationSchema: YUP.object({
      title: YUP.string().required(translations.Globals.field_req),
      description: YUP.string().required(translations.Globals.field_req),
      location: YUP.string().required(translations.Globals.field_req),
      entries: YUP.string()
        .required(translations.Globals.field_req)
        .test(
          "is-greater-than-prefilled",
          `Entries should not be less than ${prefilledEntries}`,
          function (value) {
            return parseInt(value, 10) >= prefilledEntries;
          }
        ),
      startDate: YUP.string().required(translations.Globals.field_req),
      endDate: YUP.string().required(translations.Globals.field_req),
    }),

    onSubmit: async (values) => {
      if (!media) {
        showError(translations.Globals.img_vid);
        return;
      }
      if (!id && !challengeList?.length) {
        showError("Please add atleast one challenge");
        return;
      }

      formik.setSubmitting(true);
      const body = {
        title: values.title,
        description: values.description,
        startTime: moment.utc(values.startDate).toISOString(),
        endTime: moment.utc(values.endDate).toISOString(),
        completeAddress: values.location,
        // categoryName: category,
        noOfEntries: values.entries,
        latitude: values.latitude,
        longitude: values.longitude,
        categoryId: cId,
        challengeList: id ? list : challengeList,
        city: values.city,
        country: values.country,
        zipCode: values.zipCode,
        state: values.state,
        uploads: [{ link: media, type: type, height: 4, width: 4 }],

        appKey: new Date().toISOString(),
      };

      console.log(body, "body");

      if (id) {
        try {
          const response: any = await updateChallenge({
            id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.update);
            navigate("/manage-challenges");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const response: any = await addChallenge({
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.challenge_created);
            navigate("/manage-challenges");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      fetchChallenges(id);
    }
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {translations.manage_challenge.edit || ""}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-challenges");
              }}
            >
              {translations.Globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.upload || ""}
                    </Typography>
                    {media ? (
                      <div className="upload_image_preview2">
                        {type === "IMAGE" ? (
                          <CardMedia
                            component="img"
                            image={media}
                            alt="photo"
                          />
                        ) : (
                          <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="video_dv"
                            preload="yes"
                          >
                            <source src={media} type="video/mp4" />
                          </video>
                        )}
                        <CancelIcon onClick={handleCancel} />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/*,video/*",
                            }}
                            onChange={(e) => {
                              handleImageUpload(
                                e as ChangeEvent<HTMLInputElement>
                              );
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.title || ""}
                      <span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder={translations.manage_challenge.title || ""}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.title && formik.errors.title}
                        </h6>
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.location || ""}
                    </Typography>

                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        disabled={id ? true : false}
                        type={"text"}
                        fullWidth
                        placeholder={
                          translations.manage_challenge.location || ""
                        }
                        name="location"
                        variant="outlined"
                        id="location"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                        helperText={
                          formik.touched.location && formik.errors.location
                        }
                      />
                    </Autocomplete>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.allowed_entries || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="entries"
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                      value={formik.values.entries}
                      fullWidth
                      placeholder={translations.Globals.no_allowed}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (value === "") {
                          formik.setFieldValue("entries", "");
                          formik.setFieldError("entries", "");
                        } else if (isNumber(value)) {
                          if (parseInt(value, 10) >= prefilledEntries) {
                            formik.setFieldValue("entries", value);
                            formik.setFieldError("entries", "");
                          } else {
                            formik.setFieldValue("entries", value); // Allow the value but show an error
                            formik.setFieldError(
                              "entries",
                              `No. of entries should not be less than ${prefilledEntries}`
                            );
                          }
                        }
                      }}
                      helperText={
                        formik.touched.entries && formik.errors.entries
                      }
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.select_category || ""}
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          {translations.Globals.select}
                        </MenuItem>

                        {categories?.map((item: any, index: any) => (
                          <MenuItem value={item?.name}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.start_date || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      disabled={id ? true : false}
                      name="startDate"
                      placeholder="Start Date"
                      fullWidth
                      value={formik.values.startDate}
                      variant="outlined"
                      type={"datetime-local"}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "3000-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.end_date || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="End Date"
                      disabled={id ? true : false}
                      fullWidth
                      name="endDate"
                      value={formik.values.endDate}
                      variant="outlined"
                      type={"datetime-local"}
                      inputProps={{
                        min: formik.values.startDate,
                        max: "3000-12-31",
                      }}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.desc || ""}
                      <span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      variant="outlined"
                      className="text_field"
                      value={formik.values.description}
                      fullWidth
                      placeholder={translations.manage_challenge.desc || ""}
                      multiline
                      minRows={4}
                      maxRows={4}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.description &&
                            formik.errors.description}
                        </h6>
                      }
                    />
                  </Grid>

                  {id ? null : <Grid item lg={6} md={6} sm={6} xs={12} />}

                  {id ? null : (
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <TextField
                          fullWidth
                          placeholder="Add Challenge"
                          inputProps={{ maxLength: 100 }}
                          value={currentChallenge}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              setCurrentChallenge(val.target.value);
                            }
                          }}
                        />
                        <IconButton
                          onClick={handleAddChallenge}
                          color="primary"
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Box>

                      {error && currentChallenge.trim() === "" ? (
                        <p className="err_msg">This field is required</p>
                      ) : null}
                    </Grid>
                  )}
                  <Grid item lg={6} md={6} sm={6} xs={12} />

                  {challengeList?.length ? (
                    <Grid item xs={6}>
                      {challengeList?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: "10px",
                            marginBottom: "6px",
                          }}
                          key={index}
                        >
                          <TextField
                            fullWidth
                            inputProps={{ maxLength: 40 }}
                            disabled
                            type="text"
                            value={item.name || ""}
                          />

                          <IconButton
                            onClick={() => handleDeleteChallenge(index)}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Grid>
                  ) : null}
                  {challenge?.challengeList?.length ? (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          {translations.manage_challenge.list || ""}
                        </Typography>

                        {list?.map((item: any, indexName: any) => (
                          <Grid
                            sx={{ marginBottom: "8px" }}
                            key={indexName}
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <TextField
                              type={"text"}
                              value={list[indexName]?.name}
                              placeholder="Challenge Name"
                              fullWidth
                              onChange={(e) => {
                                let value = e.target.value;
                                let newList = [...list];
                                newList[indexName] = {
                                  ...newList[indexName],
                                  name: value,
                                };
                                setList([...newList]);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.submit || ""}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddChallenge;
