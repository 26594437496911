import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  DialogContent,
  TextField,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Payments } from "../../features";
import useTranslation from "../../hooks/Translation";
import { useLazyGetPaymentHistoryQuery } from "../../services/gifts";
import { showError } from "../../constants";
import SubscriptionPayments from "../../features/revenue/SubscriptionPayment";
import { adsHistory } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRevenue = () => {
  const navigate = useNavigate();
  const translations = useTranslation() as any;
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paymentList, setPaymentList] = useState([]);
  const [ads,setAds]=useState<adsHistory[]>([])
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [paymentHistory, { isLoading }] = useLazyGetPaymentHistoryQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const fetchPaymentHistory = async () => {
    try {
      const response = await paymentHistory({
        
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();

      if(response?.statusCode===200){
        setAds(response?.data?.data)
        setTotalCount(response?.data?.total)

      }

      // if (response?.statusCode === 200) {
      //   setPaymentList(response?.data?. || []);
      //   setTotalCount(response?.data?.count);
      // } else {
      //   setPaymentList([]);
      // }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  // useEffect(() => {
  //   if(value===0){

  //     fetchPaymentHistory();
  //   }
  // }, [debouncedSearchTerm, page, value]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_payments.manage_payments || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={translations.manage_payments.ads || ""}
                  {...a11yProps(0)}
                />
                <Tab
                  label={translations.manage_payments.subscription || ""}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Payments />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SubscriptionPayments />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageRevenue;
