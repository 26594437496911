import { END_POINTS } from "../constants/url";
import { Comments, PostResponse, ReportedPosts } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
  advertiserStatus?: string;
};

type GetAllPostsResponse = {
  post: PostResponse[];
  count: number;
};
type GetAllReportedPostsResponse = {
  reportPost: ReportedPosts[];
  count: number;
};
type CommentResponse = {
  comment: Comments[];
  commentCount: number;
};
type ReportCommentResponse = {
  reportComment: Comments[];
  reportCommentCount: number;
};

export const advertiserApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query<
      CommonResponseType & { data: GetAllPostsResponse },
      GetTokenParams
    >({
      query: ({ page, limit,query }) => ({
        url: `${END_POINTS.post}?search=${query}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getReportedPosts: builder.query<
      CommonResponseType & { data: GetAllReportedPostsResponse },
      GetTokenParams
    >({
      query: ({ page, limit,query }) => ({
        url: `${END_POINTS.reportPost}?search=${query}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getPostById: builder.query<
      CommonResponseType & { data: PostResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.post}/${id}`,
        method: "GET",
      }),
    }),
    changePostStatus: builder.query<
      CommonResponseType & { data: PostResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.postStatusChange}/${id}`,
        method: "GET",
      }),
    }),

    deletePostById: builder.query<
      CommonResponseType & { data: PostResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.post}/${id}`,
        method: "DELETE",
      }),
    }),
    deleteReportedById: builder.query<
      CommonResponseType & { data: PostResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.reportPost}/${id}`,
        method: "DELETE",
      }),
    }),

    getCommentByPost: builder.query<
      CommonResponseType & { data: CommentResponse },
      { id: string | undefined; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.postComments}/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    getReportCommentByPost: builder.query<
      CommonResponseType & { data: ReportCommentResponse },
      { id: string | undefined; page: number; limit: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.reportComments}/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getStoryById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.story}/${id}`,
        method: "GET",
      }),
    }),
    deleteCommentById: builder.query<
      CommonResponseType & { data: PostResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.comment}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetPostsQuery,
  useLazyGetPostByIdQuery,
  useLazyChangePostStatusQuery,
  useLazyDeletePostByIdQuery,
  useLazyGetCommentByPostQuery,
  useLazyGetReportCommentByPostQuery,
  useLazyGetStoryByIdQuery,
  useLazyGetReportedPostsQuery,
  useLazyDeleteReportedByIdQuery,
  useLazyDeleteCommentByIdQuery,
} = advertiserApi;
