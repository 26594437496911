import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import MainContainer from "../../layout/MainContainer";
import {
  useAdvertisementRequestMutation,
  useLazyGetAdvertisementQuery,
} from "../../services/advertiser";
import { showError, showToast } from "../../constants";
import { AdminPanel } from "../../types/General";
import moment from "moment";
import useTranslation from "../../hooks/Translation";

const AdvertisementDetails = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<number>(1);
  const translations = useTranslation() as any;
  // const [open, setOpen] = useState<boolean>(false);
  const [getAdvertisement, { isLoading }] = useLazyGetAdvertisementQuery();
  const [advertisement, setAdvertisement] = useState<AdminPanel[]>([]);
  const [acceptRejectAdvertiser, GetApprovalData] =
    useAdvertisementRequestMutation();
  const { id } = useParams();

  const getAdvertisementList = async (id: string) => {
    try {
      const response: any = await getAdvertisement({
        id,
      });
      if (response?.data?.statusCode === 200) {
        setAdvertisement(response?.data?.data?.advertisements);
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleAdvertiserApproval = async (id: string, status: string) => {
    // const status=event.target.value as string;
    let body = {
      advertisementId: id,
      status: status,
      appKey: new Date().toISOString(),
    };
    console.log(body);
    try {
      const response = await acceptRejectAdvertiser({
        body: body,
      }).unwrap();
      console.log(response, "fff");
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        if (response?.data?.status === "Accepted") {
          navigate("/manage-advertisements");
        } else {
          // state.setValue(2)
          navigate("/manage-advertisements");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getAdvertisementList(id);
    }
  }, []);

  console.log("add", advertisement);
  console.log("State", state.userStatus);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_advertisements_details.view_details || ""}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
           {translations.Globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box view_box_list">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5"> {translations.manage_advertisements_details.image || ""}</Typography>

                  {advertisement[0]?.uploads[0]?.type === "VIDEO" ? (
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="hm_vd"
                      preload="yes"
                      key={advertisement[0]?._id}
                    >
                      <source
                        src={
                          advertisement[0]?.uploads[0]?.link ||
                          "/static/images/post.png"
                        }
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <figure className="profile_img">
                      <img
                        src={
                          advertisement[0]?.uploads[0]?.link ||
                          "/static/images/post.png"
                        }
                        alt=""
                      />
                    </figure>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} />
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.title || ""}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.web_link || ""}
                  </Typography>
                  <Typography component="p">
                    <a
                      href={advertisement[0]?.webLink}
                      target="_blank"
                      rel="noreffer"
                    >
                      {advertisement[0]?.webLink}
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.no_of_hours ||
                      ""}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.noOfHours}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.hourly_charge ||
                      ""}
                  </Typography>
                  <Typography component="p">
                    AED {advertisement[0]?.HourlyCharge}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.total_cost ||
                      ""}
                  </Typography>
                  <Typography component="p">
                    AED {advertisement[0]?.totalCost}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.created_date ||
                      ""}
                  </Typography>
                  <Typography component="p">
                    {" "}
                    {moment(advertisement[0]?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="heading">
                  <Typography component="h5">
                    {translations.manage_advertisements_details
                      .advertiser_details || ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details
                      .advertiser_name || ""}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.fullName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.email || ""}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translations.manage_advertisements_details.phone || ""}
                  </Typography>
                  <Typography component="p">+971056734321</Typography>
                </Box>
              </Grid>

              {/* <Grid item xs={12}>
                <Box>
                  <Typography component="h5">Documents</Typography>
                  <Box className="docs_div">
                    <figure>
                      <VisibilityIcon onClick={() => setOpen(true)} />

                      <img src="/static/images/document.png" alt="" />
                    </figure>
                    <figure>
                      <VisibilityIcon onClick={() => setOpen(true)} />
                      <img src="/static/images/document.png" alt="" />
                    </figure>
                  </Box>
                </Box>
              </Grid> */}
              <Grid item xs={12}>
                {state.userStatus === "Pending" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          padding: "20px 0 0",
                          fontSize: "20px",
                          color: "#1d1d1d",
                          fontWeight: "700",
                        }}
                        component="h2"
                      >
                        {translations.manage_advertisements_details.approval ||
                          ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={() => {
                          handleAdvertiserApproval(
                            advertisement[0]?._id,
                            "Accepted"
                          );
                        }}
                      >
                        {translations.manage_advertisements_details.accept ||
                          ""}
                      </Button>
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={() => {
                          handleAdvertiserApproval(
                            advertisement[0]?._id,
                            "Rejected"
                          );
                        }}
                      >
                        {translations.manage_advertisements_details.reject ||
                          ""}
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              {/* </Grid> */}
              {/* </Grid> */}
            </Grid>
          </CardContent>
        </Card>

        {/* {state.userStatus === "ACCEPTED" ? (
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <h2 className="mn_hdng">Ads History</h2>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Url</TableCell>
                      <TableCell>Created on</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{1}</TableCell>
                      <TableCell>
                        <figure className="bnr_img">
                          <img
                            src={"/static/images/user_placeholder.png"}
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>
                      <TableCell>www.northernlights.com</TableCell>
                      <TableCell>12/01/2024</TableCell>
                      <TableCell>$100</TableCell>
                      <TableCell>Accepted</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        ) : null} */}
        {/* <DocumentViewer open={open} setOpen={setOpen} /> */}
      </div>
    </MainContainer>
  );
};

export default AdvertisementDetails;
