import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import {
  useLazyBlockUnblockQuery,
  useLazyDeleteAdvertisementQuery,
  useLazyGetAdvertisementQuery,
} from "../../services/advertiser";
import { showError } from "../../constants";
import { AdminPanel, AdvertiserResponse } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import moment from "moment";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import useTranslation from "../../hooks/Translation";

type props = {
  hidePermission: Permissions | null | undefined;
  advertisement: AdminPanel[];
  setAdvertisement: Dispatch<SetStateAction<AdminPanel[]>>;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  page: number;
  totalPages: number;

  onPageChange: (newPage: number) => void;
  getAdvertisersList: () => void;
};

const Accepted = ({
  hidePermission,
  advertisement,
  setAdvertisement,
  setDebouncedSearchTerm,
  searchTerm,
  setSearchTerm,
  page,
  totalPages,
  onPageChange,
  getAdvertisersList,
}: props) => {
  const userData = useAuth();
  const navigate = useNavigate();

  const [blockUnblockQuery] = useLazyBlockUnblockQuery();

  const [open, setOpen] = useState<boolean>(false);
  const [selectId, setSelectedId] = useState<string>("");

  const [deleteById] = useLazyDeleteAdvertisementQuery();

  const translations = useTranslation() as any;
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  return (
    <div>
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
        {/* <Box className="cards_header_right">
        <Button className="btn btn_primary">
          <FileDownloadIcon /> Export CSV
        </Button>
        <Button
          className="btn btn_primary"
          onClick={() => navigate("/manage-advertisers/add")}
        >
          Add Advertiser
        </Button>
      </Box> */}
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {translations.table_heads.sno || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.ad_image || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.advertiser_details || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.ad_title || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.web_link || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.created_Date || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.total_cost || ""}
              </TableCell>
              <TableCell align="center">
                {" "}
                {translations.table_heads.status || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.actions || ""}
              </TableCell>
            </TableRow>
          </TableHead>

          {advertisement?.length ? (
            advertisement?.map((item, index) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.uploads[0]?.type === "IMAGE" ? (
                      <figure className="bnr_img">
                        <img
                          src={
                            item?.uploads[0]?.link || "/static/images/post.png"
                          }
                          alt=""
                        />
                      </figure>
                    ) : (
                      <figure className="bnr_img">
                        <img
                          src={
                            item?.uploads[0]?.thumbnail ||
                            "/static/images/post.png"
                          }
                          alt=""
                        />
                      </figure>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>{item?.fullName}</p>
                    <p>{item?.email}</p>
                  </TableCell>
                  <TableCell>{item?.title}</TableCell>
                  <TableCell>
                    <a href={item?.webLink}>{item?.webLink}</a>
                  </TableCell>
                  <TableCell>
                    {moment(item?.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>AED {item?.totalCost}</TableCell>
                  <TableCell>
                    <Switch
                      onChange={() => {
                        updateStatus(
                          item?._id,
                          !item?.isBlocked,
                          blockUnblockQuery,
                          setAdvertisement,
                          advertisement
                        );
                      }}
                      {...label}
                      checked={!item.isBlocked}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(
                            "/manage-advertisements/details/" + item?._id,
                            {
                              state: { userStatus: "ACCEPTED" },
                            }
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}
                      {hidePermission?.isDelete ||
                      userData?.role === "ADMIN" ? (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(item?._id);
                          }}
                          // onClick={() => {
                          //   setOpen(true);
                          //   setSelectedId(item?._id);
                          // }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                {translations.manage_advertisements.no_found || ""}
              </TableCell>
            </TableBody>
          )}

          {/* <TableBody>
            {/* {rows.map((row, i) => ( */}
          {/* <TableRow>
              <TableCell align="center">{1}</TableCell>
              <TableCell>12/12/2023</TableCell>
              <TableCell>
                <p>Abdul Hussain</p>
                <p>abdul@yopmail.com</p>
              </TableCell>
              <TableCell>See the Northern Lights</TableCell>
              <TableCell>www.northernlights.com</TableCell>
              <TableCell>$10</TableCell>

              <TableCell>
                <Switch {...label} defaultChecked size="small" />
              </TableCell>
              <TableCell>
                <Box className="table_actions">
                  <IconButton
                    onClick={() =>
                      navigate("/manage-advertisements/details", {
                        state: { userStatus: "ACCEPTED" },
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
            //       </IconButton> */}
          {/* //          {hidePermission?.isDelete || */}

          {/* //       <IconButton> */}
          {/* //         <DeleteIcon /> */}
          {/* //       </IconButton> */}

          {/* //     </Box> */}
          {/* //   </TableCell> */}
          {/* // </TableRow> */}
          {/* ))} */}
          {/* /* </TableBody>  */}
        </Table>
      </TableContainer>
      <Pagination
        module={advertisement}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={translations.manage_advertisements.advertisement || ""}
        handleDelete={() => {
          handleDelete(deleteById, selectId, getAdvertisersList);
        }}
      />
    </div>
  );
};

export default Accepted;
