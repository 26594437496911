import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import {
  useLazyDeleteCommentByIdQuery,
  useLazyGetCommentByPostQuery,
  useLazyGetPostByIdQuery,
  useLazyGetReportCommentByPostQuery,
} from "../../services/posts";
import { Loader, showError } from "../../constants";
import { Comments, PostResponse } from "../../types/General";
import { DocumentViewer, Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const [getCommentByPost] = useLazyGetCommentByPostQuery();
  const [reportedComment] = useLazyGetReportCommentByPostQuery();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const [value, setValue] = React.useState(0);
  const [postDetail, setPostDetail] = useState<PostResponse>();
  const [comments, setComments] = useState<Comments[]>([]);
  const [reportComments, setReportComments] = useState<Comments[]>([]);
  const [uploadPreview, setUploadPreview] = useState<string>("");
  const [type, setType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedComment, setSelectedComment] = useState<Comments>();

  const [getPostById, { isLoading }] = useLazyGetPostByIdQuery();
  const [deleteCommentById] = useLazyDeleteCommentByIdQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getPostDetail = async (id: string) => {
    try {
      const response = await getPostById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPostDetail(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getReportComments = async (id: string | undefined) => {
    try {
      const response = await reportedComment({
        id: id,
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setReportComments(response?.data?.reportComment);
        setTotalCount(response?.data?.reportCommentCount);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getComments = async (id: string | undefined) => {
    try {
      const response = await getCommentByPost({
        id: id,
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setComments(response?.data?.comment);
        setTotalCount(response?.data?.commentCount);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handlePopup = (image: string, type: string) => {
    setOpen(true);
    setUploadPreview(image);
    setType(type);
  };

  useEffect(() => {
    if (id) {
      getPostDetail(id);
    }
  }, []);

  useEffect(() => {
    if (value === 0) {
      getReportComments(id);
    } else {
      getComments(id);
    }
  }, [value, page]);
  const translations = useTranslation() as any;
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_content.content_details}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              // eslint-disable-next-line no-lone-blocks
              {
                state.page === "USER"
                  ? navigate(`/manageusers/userdetails/${postDetail?.userId}`)
                  : navigate("/manage-content");
              }
            }}
          >
            {translations.Globals.back || ""}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  className="detail_title mn_hdng"
                  component="h2"
                  mb={3}
                >
                  {translations.manage_content.user_details || ""}
                </Typography>
              </Grid>
              {/* <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid> */}
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_content.user_name || ""}
                      </Typography>
                      <Typography component="p">
                        {postDetail?.userName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_content.phone || ""}
                      </Typography>
                      <Typography component="p">
                        {" "}
                        {postDetail?.userCountryCode
                          ? (postDetail?.userCountryCode.includes("+")
                              ? ""
                              : "+") + postDetail?.userCountryCode
                          : null}{" "}
                        {postDetail?.userPhone || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_content.email || ""}
                      </Typography>
                      <Typography component="p">
                        {postDetail?.userEmail || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      {translations.manage_content.details || ""}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translations.manage_content.uploads || ""}
                          </Typography>
                          <Box className="post_div">
                            {postDetail?.uploads?.length
                              ? postDetail?.uploads?.map((item) => {
                                  return (
                                    <>
                                      {item?.type === "IMAGE" ? (
                                        <figure
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handlePopup(item?.link, item?.type)
                                          }
                                        >
                                          <img src={item?.link || ""} alt="" />
                                        </figure>
                                      ) : (
                                        <video
                                          style={{ cursor: "pointer" }}
                                          autoPlay
                                          muted
                                          loop
                                          playsInline
                                          className="hm_vd"
                                          preload="yes"
                                          onClick={() =>
                                            handlePopup(item?.link, item?.type)
                                          }
                                        >
                                          <source
                                            src={item?.link || ""}
                                            type="video/mp4"
                                          />
                                        </video>
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translations.manage_content.desc || ""}
                          </Typography>
                          <Typography component="p">
                            {" "}
                            {postDetail?.comment || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Date</Typography>
                          <Typography component="p">
                            {" "}
                            {moment(postDetail?.createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translations.manage_content.upload_date || ""}
                          </Typography>
                          <Typography component="p">
                            {moment(postDetail?.createdAt).format("h:mm A")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translations.manage_content.total_comments || ""}
                          </Typography>
                          <Typography component="p">
                            {postDetail?.commentCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translations.manage_content.total_likes || ""}
                          </Typography>
                          <Typography component="p">
                            {postDetail?.likesCount}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={translations.manage_content.reported_comments || ""}
                  {...a11yProps(0)}
                />
                <Tab
                  label={translations.manage_content.new_comments || ""}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translations.table_heads.sno || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.comments || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.report || ""}
                      </TableCell>

                      {/* <TableCell align="center">Likes</TableCell>
                      <TableCell align="center">No. of Replies</TableCell> */}
                      <TableCell>
                        {translations.table_heads.user_details || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.actions || ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {reportComments?.length > 0 ? (
                    reportComments?.map((row, i) => (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell align="center">
                            {row?.commentText}
                          </TableCell>
                          <TableCell align="center">
                            {row?.reportContent || ""}
                          </TableCell>

                          <TableCell>
                            <Typography variant="h6" fontSize={14}>
                              {row?.userFullName || ""}
                            </Typography>
                            <Typography variant="h6" fontSize={14}>
                              {row?.userEmail || ""}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              {/* <IconButton>
                                <VisibilityIcon />
                              </IconButton> */}
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setSelectedId(row?._id);
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_content.not_comments || ""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translations.table_heads.sno || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.comments || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.likes || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.replies || ""}
                      </TableCell>

                      <TableCell>
                        {translations.table_heads.user_details || ""}
                      </TableCell>

                      <TableCell>
                        {translations.table_heads.actions || ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {comments?.length > 0 ? (
                    comments?.map((row, i) => (
                      <TableBody>
                        <TableRow key={row?._id}>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell align="center">
                            {row?.commentText}
                          </TableCell>
                          <TableCell align="center">
                            {row?.commentLikesCount}
                          </TableCell>
                          <TableCell align="center">
                            {row?.commentReply?.length}
                          </TableCell>

                          <TableCell>
                            <Typography variant="h6" fontSize={14}>
                              {row?.userFullName}
                            </Typography>
                            <Typography variant="h6" fontSize={14}>
                              {row?.userEmail || ""}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() => {
                                  setModalOpen(true);
                                  setSelectedComment(row);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setSelectedId(row?._id);
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_content.not_comments || ""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        {value === 0 ? (
          <WarnModal
            setOpen={setOpenDelete}
            open={openDelete}
            name="comment"
            handleDelete={() =>
              handleDelete(deleteCommentById, selectedId, () =>
                getReportComments(id)
              )
            }
          />
        ) : (
          <WarnModal
            setOpen={setOpenDelete}
            open={openDelete}
            name="comment"
            handleDelete={() =>
              handleDelete(deleteCommentById, selectedId, () => getComments(id))
            }
          />
        )}
        <Pagination
          module={value === 0 ? reportComments : comments}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <DocumentViewer
        open={open}
        setOpen={setOpen}
        image={uploadPreview}
        type={type}
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            padding: "20px",
            maxHeight: "80%",
            overflowY: "auto",
            minWidth: "60%",
            maxWidth: "60%",
          }}
        >
          <Typography variant="h6">Replies</Typography>
          <ul className="cmnt_rplies">
            {selectedComment?.commentReply?.length ? (
              selectedComment?.commentReply?.map((item) => {
                return (
                  <li className="rply">
                    <div className="user_dtls">
                      <figure>
                        <img
                          src={
                            item?.commentReplyUserImage ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div className="userss">
                        <h2>Full Name: {item?.commentReplyUserName || "-"}</h2>
                        <h2>Reply: {item?.commentText || "-"}</h2>
                        <h2>
                          Date: {moment(item?.createdAt).format("DD/MM/YYYY")}
                        </h2>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div>
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  No Replies Found
                </h2>
              </div>
            )}
          </ul>
        </Paper>
      </Modal>
    </MainContainer>
  );
};

export default ContentDetails;
