import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Tabs, Tab, Typography } from "@mui/material";
import Accepted from "../../features/manageAdvertisements/Accepted";
import Pending from "../../features/manageAdvertisements/Pending";
import Rejected from "../../features/manageAdvertisements/Rejected";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../../types/User";
import { AdminPanel } from "../../types/General";
import { useLazyGetAdvertisementQuery } from "../../services/advertiser";
import { showError } from "../../constants";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAdvertisements = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translation=useTranslation()as any
  const [value, setValue] = useState<number>(0);
  const [advertisements,setAdvertisements]=useState<AdminPanel[]>([])
  const[getAdvertisement,{isLoading}]=useLazyGetAdvertisementQuery()
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Advertisements"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getAdvertisementList=async()=>{
    try{
        const response:any=await getAdvertisement({
            advertiserStatus: value === 0 ? "Accepted" : value === 1 ? "Pending" : "Rejected",
            query:debouncedSearchTerm.trim(),
            page:page,
            limit:10
        })
        console.log("dd",response)
        if(response?.data?.statusCode===200){
          setAdvertisements(response?.data?.data?.advertisements || [])
          setTotalCount(response?.data?.data?.count)
        }else{
          setAdvertisements([])
        }

    }catch(error:any){
      showError(error?.data?.message || "")
    }
  }

  let totalPages = Math.ceil(totalCount / 10);
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(()=>{
    getAdvertisementList()
  },[debouncedSearchTerm,searchTerm,page,value])
console.log("rr",advertisements)
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translation.sub_admin.manage_advertisements}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translation.Globals.accept} {...a11yProps(0)} />
                <Tab label={translation.Globals.pending} {...a11yProps(1)} />
                <Tab label={translation.Globals.rejected} {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
              <Accepted
              advertisement={advertisements}
              setAdvertisement={setAdvertisements}
              page={page}
              
               setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                totalPages={totalPages}
                onPageChange={onPageChange}
                
                getAdvertisersList={getAdvertisementList}
               hidePermission={hidePermission}
               />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} >
              <Pending 
              advertisement={advertisements}
              setAdvertisement={setAdvertisements}
              page={page}
              
               setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                totalPages={totalPages}
                onPageChange={onPageChange}
                setValue={setValue}
                getAdvertisersList={getAdvertisementList}
              hidePermission={hidePermission}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} >
              <Rejected 
              advertisement={advertisements}
              setAdvertisement={setAdvertisements}
              page={page}
              
               setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                totalPages={totalPages}
                onPageChange={onPageChange}
                setValue={setValue}
                getAdvertisersList={getAdvertisementList}
              hidePermission={hidePermission}/>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageAdvertisements;
