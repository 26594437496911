import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Accepted from "../../features/serviceProvider/Accepted";
import Rejected from "../../features/serviceProvider/Rejected";
import Pending from "../../features/serviceProvider/Pending";
import { AdvertiserResponse } from "../../types/General";
import {
  useLazyGetAdvertiserCSVQuery,
  useLazyGetAdvertisersQuery,
} from "../../services/advertiser";
import { Loader, showError } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageServiceProvider = () => {
  const userData = useAuth();
const navigate= useNavigate();
const translation=useTranslation()as any
  const [value, setValue] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState(1);
  const [advertisers, setAdvertisers] = useState<AdvertiserResponse[]>([]);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);

  const [getAdvertisers, { isLoading }] = useLazyGetAdvertisersQuery();
  const [getAdvertiserCSV] = useLazyGetAdvertiserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  const handleExportCsv = async () => {
    try {
      const res = await getAdvertiserCSV({
        advertiserStatus:
          value === 0 ? "Accepted" : value === 1 ? "Pending" : "Rejected",
          search:searchTerm
      }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const getAdvertisersList = async () => {
    try {
      const response = await getAdvertisers({
        advertiserStatus:
          value === 0 ? "Accepted" : value === 1 ? "Pending" : "Rejected",
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAdvertisers(response?.data?.advertiser || []);
        setTotalCount(response?.data?.count);
      } else {
        setAdvertisers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Advertisers"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getAdvertisersList();
  }, [debouncedSearchTerm, page, value]);
 
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.manage_advertisers.manage_advertiser||""}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translation.Globals.accept} {...a11yProps(0)} />
                <Tab label={translation.Globals.pending} {...a11yProps(1)} />
                <Tab label={translation.Globals.rejected} {...a11yProps(2)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <Accepted
                advertisers={advertisers}
                setAdvertisers={setAdvertisers}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                getAdvertisersList={getAdvertisersList}
                handleExportCsv={handleExportCsv}
                hidePermission={hidePermission}
              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Pending
                advertisers={advertisers}
                setAdvertisers={setAdvertisers}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                getAdvertisersList={getAdvertisersList}
                handleExportCsv={handleExportCsv}
                hidePermission={hidePermission}
                setValue={setValue}

              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <Rejected
                advertisers={advertisers}
                setAdvertisers={setAdvertisers}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                getAdvertisersList={getAdvertisersList}
                handleExportCsv={handleExportCsv}
                hidePermission={hidePermission}

              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageServiceProvider;
