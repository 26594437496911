import { url } from "inspector";
import { END_POINTS } from "../constants/url";
import {
  AdminPanel,
  AdvertiserResponse,
  ApiResponse,
  Journey,
  NotificationData,
  ReportedJourney,
  Reward,
  SupportTicket,
} from "../types/General";
import emptySplitApi from "../utils/rtk";
import { METHODS } from "http";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
  advertiserStatus?: string;
  id?: string;
};

type GetAllAdvertisersResponse = {
  advertiser: AdvertiserResponse[];
  count: number;
};

type customerQuery = {
  limit?: number;
  page?: number;
  search?: string;
  id?: string;
};

type AddAdvertiserBody = {
  image: string;
  email?: string;
  fullName: string;
  phone?: string;
  countryCode?: string;
  address: string;
  city: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
  documentName: string;
  documentExpiryDate: string;
  documentFrontImage: string;
  documentBackImage: string;
};

type JourneyResponse = {
  journeyCount: number;
  journey: Journey[];
  message: string;
};

type RewardsBody = {
  title?: string;
  title_ar?: string;
  image?: string;
  noOfJourneys?: string;
  noOfPosts?: string;
  noOfChallenges?: string;
  pointsEarned?: string;
};

type ApprovalBody = {
  advertiserId: string | undefined;
  status: string;
};

type SupportQueryResponse = {
  count: number;
  queries: SupportTicket[];
  message: string;
};

type ReportData = {
  reportJourney: ReportedJourney[];
  count: number;
};
type RewardsResponse = {
  count: number;
  rewards: Reward[];
  message: string;
};
type SupportQueryWithDelete = {
  userName: string;
  email: string;
  description: string;
  isBlocked: boolean;
  isDeleted: boolean;
  issueType: string;
  isAdvertiser: boolean;
  status: number;
  reply: string;
  _id: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export const advertiserApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAdvertiser: builder.mutation<
      CommonResponseType & { data: AdvertiserResponse },
      AddAdvertiserBody
    >({
      query: (body) => ({
        url: `${END_POINTS.advertiser}`,
        method: "POST",
        body,
      }),
    }),

    getAdvertisers: builder.query<
      CommonResponseType & { data: GetAllAdvertisersResponse },
      GetTokenParams
    >({
      query: ({ advertiserStatus, page, limit, query }) => ({
        url: `${END_POINTS.advertiser}?status=${advertiserStatus}&page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),
    getAdvertiserById: builder.query<
      CommonResponseType & { data: AdvertiserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.advertiser}/${id}`,
        method: "GET",
      }),
    }),
    changeAdvertiserStatus: builder.query<
      CommonResponseType & { data: AdvertiserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.advertiserStatusChange}/${id}`,
        method: "GET",
      }),
    }),
    postAcceptRejectAdvertiser: builder.mutation<
      CommonResponseType & { data: AdvertiserResponse },
      ApprovalBody
    >({
      query: (body) => ({
        url: `${END_POINTS.advertiserRequest}`,
        method: "POST",
        body,
      }),
    }),

    editAdvertiserById: builder.mutation<
      CommonResponseType & { data: AdvertiserResponse },
      { id: string; body: AddAdvertiserBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.advertiser}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteAdvertiserById: builder.query<
      CommonResponseType & { data: AdvertiserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.advertiser}/${id}`,
        method: "DELETE",
      }),
    }),

    getAdvertiserCSV: builder.query<
      CommonResponseType & { data: string },
      { advertiserStatus: string; search: string }
    >({
      query: ({ advertiserStatus, search }) => ({
        url: `${END_POINTS.exportAdvertiser}?status=${advertiserStatus}&search=${search}`,
        method: "GET",
      }),
    }),
    getAdvertisement: builder.query<
      CommonResponseType & { data: AdminPanel },
      GetTokenParams
    >({
      query: ({ limit, page, query, advertiserStatus, id }) => ({
        // url:`${END_POINTS.getAdvertisement}/${id}?status=${advertiserStatus}&search=${query}&limit=${limit}&page=${page}`,
        url:
          id !== undefined
            ? `${END_POINTS.getAdvertisement}/${id}`
            : `${END_POINTS.getAdvertisement}?status=${advertiserStatus}&search=${query}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    blockUnblock: builder.query<
      CommonResponseType & { data: AdminPanel },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.blockUnblockAdvertisement}/${id}`,
        method: "GET",
      }),
    }),
    deleteAdvertisement: builder.query<
      CommonResponseType & { data: AdminPanel },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteAdvertisement}/${id}`,
        method: "DELETE",
      }),
    }),
    advertisementRequest: builder.mutation<
      CommonResponseType & { data: AdvertiserResponse },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.advertisementRequest}`,
        method: "POST",
        body,
      }),
    }),
    getComplaints: builder.query<
      CommonResponseType & { data: SupportQueryResponse },
      customerQuery
    >({
      query: ({ limit, page, search }) => ({
        url: `${END_POINTS.getComplaints}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    deleteComplaints: builder.query<
      CommonResponseType & { data: SupportQueryWithDelete },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteComplaints}/${id}`,
        method: "DELETE",
      }),
    }),

    getComplaintsById: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getComplaintsById}/${id}`,
        method: "GET",
      }),
    }),

    replyComplaints: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any; id?: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.replyComplaints}/${id}`,
        method: "POST",
        body,
      }),
    }),

    getQueries: builder.query<
      CommonResponseType & { data: SupportQueryResponse },
      customerQuery
    >({
      query: ({ limit, page, search }) => ({
        url: `${END_POINTS.getQueries}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    getQueriesById: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getQueriesById}/${id}`,
        method: "GET",
      }),
    }),

    replyQueries: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any; id?: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.replyQueries}/${id}`,
        method: "POST",
        body,
      }),
    }),

    deleteQueries: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteQueries}/${id}`,
        method: "DELETE",
      }),
    }),
    getAdvertisementAds: builder.query<
      CommonResponseType & { data: any },
      { id?: string; page?: number; limit?: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.getAdvertisement}?advertiserId=${id}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    getJourney: builder.query<
      CommonResponseType & { data: JourneyResponse },
      customerQuery
    >({
      query: ({ page, limit, search }) => ({
        url: `${END_POINTS.journey}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    deleteJourney: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.journey}/${id}`,
        method: "DELETE",
      }),
    }),
    journeyStatusChange: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.journeyStatusChange}/${id}`,
        method: "GET",
      }),
    }),
    getJourneyById: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.journey}/${id}`,
        method: "GET",
      }),
    }),

    journeyComments: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ id, limit, page }) => ({
        url: `${END_POINTS.journeyComments}/${id}?limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    reportCommentsJourney: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ id, limit, page }) => ({
        url: `${END_POINTS.reportCommentsJourney}/${id}?limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    reportJourney: builder.query<
      CommonResponseType & { data: ReportData },
      customerQuery
    >({
      query: ({ page, limit, search }) => ({
        url: `${END_POINTS.reportJourney}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    reportedJourney: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ page, limit, search }) => ({
        url: `${END_POINTS.reportJourney}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    addRewards: builder.mutation<
      CommonResponseType & { data: any },
      { body: RewardsBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.rewards}`,
        method: "POST",
        body,
      }),
    }),
    getRewards: builder.query<
      CommonResponseType & { data: RewardsResponse },
      customerQuery
    >({
      query: ({ id, limit, page, search }) => ({
        url:
          id !== undefined
            ? `${END_POINTS.rewards}/${id}`
            : `${END_POINTS.rewards}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    rewardsStatusChange: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.rewardsStatusChange}/${id}`,
        method: "PATCH",
      }),
    }),
    updateRewards: builder.mutation<
      CommonResponseType & { data: any },
      { id?: string; body: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.rewards}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteRewards: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.rewards}/${id}`,
        method: "DELETE",
      }),
    }),

    getChallenges: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ limit, page, search }) => ({
        url: `${END_POINTS.getChallenges}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    deleteChallenge: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteChallenge}/${id}`,
        method: "DELETE",
      }),
    }),
    challengeStatus: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.challengeStatusChange}/${id}`,
        method: "PATCH",
      }),
    }),

    getChallengeById: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getChallengesById}/${id}`,
        method: "GET",
      }),
    }),

    userJourney: builder.query<
      CommonResponseType & { data: any },
      { id?: string; page?: number; limit?: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.journey}?userId=${id}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    userChallenge: builder.query<
      CommonResponseType & { data: any },
      { id?: string; page?: number; limit?: number }
    >({
      query: ({ id, page, limit }) => ({
        url: `${END_POINTS.getChallengeByUserId}/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),

    updateChallenge: builder.mutation<
      CommonResponseType & { data: any },
      { id?: string; body?: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updateChallenge}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    addChallnege: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.challenge}`,
        method: "POST",
        body,
      }),
    }),

    getCategoryById: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.category}`,
        method: "GET",
      }),
    }),

    challengePoints: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.challengePoints,
        method: "GET",
      }),
    }),

    updatePoints: builder.mutation<
      CommonResponseType & { data: any },
      { id?: string; body?: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.challengePoints}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    getNotifications: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ limit, page, search }) => ({
        url: `${END_POINTS.notification}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    addNotifications: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.notification}`,
        method: "Post",
        body,
      }),
    }),

    deleteNotification: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "DELETE",
      }),
    }),

    getUsersNotification: builder.query<
      CommonResponseType & { data: NotificationData[] },
      customerQuery
    >({
      query: ({ limit }) => ({
        url: `${END_POINTS.users}`,
        method: "GET",
      }),
    }),
    getUsersNotificationPagination: builder.query<
      CommonResponseType & { data: any },
      customerQuery
    >({
      query: ({ limit, page, search }) => ({
        url:
          search === undefined
            ? `${END_POINTS.users}?limit=${limit}&page=${page}`
            : `${END_POINTS.users}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),

    subscriptionPlan: builder.mutation<
      CommonResponseType & { data: any },
      { body?: any }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.addSubscriptionPlan}`,
        method: "POST",
        body,
      }),
    }),
    getPlans: builder.query<CommonResponseType & { data: any }, customerQuery>({
      query: ({ limit, page, search }) => ({
        url:
          search === undefined
            ? `${END_POINTS.getPlans}?limit=${limit}&page=${page}`
            : `${END_POINTS.getPlans}?search=${search}&limit=${limit}&page=${page}`,
        method: "GET",
      }),
    }),
    getPlansById: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getPlans}/${id}`,
        method: "GET",
      }),
    }),

    deletePlan: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deletePlan}/${id}`,
        method: "DELETE",
      }),
    }),
    planStatus: builder.query<
      CommonResponseType & { data: any },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.planStatusChange}/${id}`,
        method: "PATCH",
      }),
    }),

    updatePlan: builder.mutation<
      CommonResponseType & { data: any },
      { id?: string; body?: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.updatePlans}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    getReceivedNOtifications: builder.query<
      CommonResponseType & { data: ApiResponse },
      customerQuery
    >({
      query: ({ page, limit, search }) => ({
        url: `${END_POINTS.notificationListing}?search=${search}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    postJourney: builder.mutation<CommonResponseType & { data: any }, any>({
      query: (body) => ({
        url: `${END_POINTS.journey}`,
        method: "POST",
        body,
      }),
    }),

    // deleteReportedJourney:builder.query<
    // CommonResponseType & {data:any},
    // {id?:string}
    // >({
    //     query:({id})=>({
    //       url:`${END_POINTS.reportJourney}?search=${search}&limit=${limit}&page=${page}` ,
    //       method:"GET"
    //     })
    // }),

    // getQueriesById:builder.query<
    // CommonResponseType&{data:any},
    // {id?.string}
    // >({
    //   query:({id})=>{
    //     url:`${END_POINTS.getQueriesById}/${id}`
    //   }
    // })
  }),
});

export const {
  usePostAdvertiserMutation,
  useLazyGetAdvertisersQuery,
  useLazyGetAdvertiserByIdQuery,
  useLazyChangeAdvertiserStatusQuery,
  useLazyDeleteAdvertiserByIdQuery,
  usePostAcceptRejectAdvertiserMutation,
  useEditAdvertiserByIdMutation,
  useLazyGetAdvertiserCSVQuery,
  useLazyGetAdvertisementQuery,
  useLazyBlockUnblockQuery,
  useLazyDeleteAdvertisementQuery,
  useAdvertisementRequestMutation,
  useLazyGetComplaintsQuery,
  useLazyDeleteComplaintsQuery,
  useLazyGetComplaintsByIdQuery,
  useReplyComplaintsMutation,
  useLazyGetQueriesQuery,
  useLazyGetQueriesByIdQuery,
  useReplyQueriesMutation,
  useLazyDeleteQueriesQuery,
  useLazyGetAdvertisementAdsQuery,
  useLazyGetJourneyQuery,
  useLazyDeleteJourneyQuery,
  useLazyJourneyStatusChangeQuery,
  useLazyGetJourneyByIdQuery,
  useLazyJourneyCommentsQuery,
  useLazyReportCommentsJourneyQuery,
  useAddRewardsMutation,
  useLazyGetRewardsQuery,
  useLazyRewardsStatusChangeQuery,
  useLazyDeleteRewardsQuery,
  useUpdateRewardsMutation,
  useLazyReportJourneyQuery,
  useLazyGetChallengesQuery,
  useLazyDeleteChallengeQuery,
  useLazyChallengeStatusQuery,
  useLazyGetChallengeByIdQuery,
  useLazyUserJourneyQuery,
  useLazyUserChallengeQuery,
  useLazyGetCategoryByIdQuery,
  useUpdateChallengeMutation,
  useLazyChallengePointsQuery,
  useUpdatePointsMutation,
  useLazyGetNotificationsQuery,
  useLazyDeleteNotificationQuery,
  useLazyGetUsersNotificationQuery,
  useAddNotificationsMutation,
  useLazyGetUsersNotificationPaginationQuery,
  useSubscriptionPlanMutation,
  useLazyGetPlansQuery,
  useLazyDeletePlanQuery,
  useLazyGetPlansByIdQuery,
  useLazyPlanStatusQuery,
  useUpdatePlanMutation,
  useLazyGetReceivedNOtificationsQuery,
  useAddChallnegeMutation,
  usePostJourneyMutation,
} = advertiserApi;
