import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../../layout/MainContainer";
import { DocumentViewer, Pagination } from "../../../components";
import {
  useLazyGetAdvertisementAdsQuery,
  useLazyGetAdvertiserByIdQuery,
  usePostAcceptRejectAdvertiserMutation,
  useLazyDeleteAdvertisementQuery,
} from "../../../services/advertiser";
import { AdvertiserResponse } from "../../../types/General";
import { Loader, showError, showToast } from "../../../constants";
import moment from "moment";
import WarnModal from "../../../components/WarnModal";
import { handleDelete } from "../../../utils/commonFunctions";
import useTranslation from "../../../hooks/Translation";

const ServiceProviderDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [modalType, setModalType] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);

  const [selectId, setSelectedId] = useState<string>("");
  const [advertiserDetails, setAdvertiserDetails] =
    useState<AdvertiserResponse>();
  const [getAdvertiserDetails, { isLoading }] = useLazyGetAdvertiserByIdQuery();
  const [getAds] = useLazyGetAdvertisementAdsQuery();
  const [ads, setAds] = useState<any>([]);
  const [count, setTotalCount] = useState<number>(0);
  const [acceptRejectAdvertiser, GetApprovalData] =
    usePostAcceptRejectAdvertiserMutation();

  const [deleteById] = useLazyDeleteAdvertisementQuery();
  const getUserAds = async () => {
    try {
      const response = await getAds({
        id: id,
        page: page,
        limit: 10,
      }).unwrap();
      if (response.statusCode === 200) {
        console.log("adsResponse", response.data.advertisements);
        setAds(response?.data?.advertisements);
        setTotalCount(response?.data?.count);
      } else {
        setAds([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  console.log(ads, "sdsadasdf");
  const getAdvertiserDetail = async (id: string) => {
    try {
      const response = await getAdvertiserDetails({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAdvertiserDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleAdvertiserApproval = async (type: string) => {
    let body = {
      advertiserId: advertiserDetails?._id,
      status: type,
      appKey: new Date().toISOString(),
    };
    try {
      const response = await acceptRejectAdvertiser(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        if (response?.data?.status === "Accepted") {
          navigate("/manage-advertisers");
        } else {
          navigate("/manage-advertisers");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getAdvertiserDetail(id);
    }
  }, []);
  useEffect(() => {
    if (id) {
      // getAdvertiserDetail(id);
    }
    getUserAds();
  }, [page]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalPages = Math.ceil(count / 10);
  console.log(advertiserDetails, "aaaaaaaa");
  const rr = moment(advertiserDetails?.documentExpiryDate).format("DD/MM/YYYY");
  console.log(rr, "dddd");
  const translations = useTranslation() as any;
  return (
    <MainContainer>
      <Loader isLoad={isLoading || GetApprovalData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_advertisements_details.view_details || ""}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisers");
            }}
          >
            {translations.Globals.back || ""}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img
                    src={
                      advertiserDetails?.image ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.fname || ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.fullName || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.email || ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.phone || ""}
                      </Typography>
                      <Typography component="p">
                        {" "}
                        {advertiserDetails?.countryCode
                          ? (advertiserDetails?.countryCode.includes("+")
                              ? ""
                              : "+") + advertiserDetails?.countryCode
                          : null}{" "}
                        {advertiserDetails?.phone || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details
                          .approval_status || ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.status || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.doc_name ||
                          ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.documentName || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.expiry ||
                          ""}
                      </Typography>
                      <Typography component="p">
                        {moment(advertiserDetails?.documentExpiryDate).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.address ||
                          ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.address || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details
                          .ads_activated || ""}
                      </Typography>
                      <Typography component="p">
                        {advertiserDetails?.activeAds}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details
                          .ads_deactivated || ""}
                      </Typography>
                      <Typography component="p">
                        {" "}
                        {advertiserDetails?.deactivatedAds}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details
                          .ads_completed || ""}
                      </Typography>
                      <Typography component="p">
                        {" "}
                        {advertiserDetails?.completedAds}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translations.manage_advertisements_details.docs || ""}
                      </Typography>
                      <Box className="docs_div">
                        <figure>
                          <VisibilityIcon
                            onClick={() => {
                              setOpen(true);
                              setModalType(1);
                            }}
                          />

                          <img
                            src={
                              advertiserDetails?.documentFrontImage ||
                              "/static/images/placeholder_doc.jpeg"
                            }
                            alt=""
                          />
                        </figure>
                        <figure>
                          <VisibilityIcon
                            onClick={() => {
                              setOpen(true);
                              setModalType(2);
                            }}
                          />
                          <img
                            src={
                              advertiserDetails?.documentBackImage ||
                              "/static/images/placeholder_doc.jpeg"
                            }
                            alt=""
                          />
                        </figure>
                      </Box>
                    </Box>
                  </Grid>
                  {state.userStatus === "PENDING" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          {translations.manage_advertisements_details
                            .approval || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={() => handleAdvertiserApproval("Accepted")}
                        >
                          {translations.manage_advertisements_details.accept ||
                            ""}
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={() => handleAdvertiserApproval("Rejected")}
                        >
                          {translations.manage_advertisements_details.reject ||
                            ""}
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {state.userStatus === "ACCEPTED" ? (
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <h2 className="mn_hdng">
                {translations.manage_advertisements_details.ads_history || ""}
              </h2>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translations.table_heads.sno || ""}
                      </TableCell>
                      <TableCell align="center">
                        {translations.table_heads.image || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.title || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.url || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.created_on || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.price || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.status || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.actions || ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {ads?.length ? (
                    ads?.map((item: any, index: any) => (
                      <TableBody key={index}>
                        <TableRow>
                          <TableCell> {(page - 1) * 10 + index + 1}</TableCell>
                          <TableCell>
                            {item?.uploads[0]?.type === "IMAGE" ? (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    item?.uploads[0]?.link ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            ) : (
                              <figure className="bnr_img">
                                <img
                                  src={
                                    item?.uploads[0]?.thumbnail ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            )}
                          </TableCell>
                          <TableCell>{item?.title}</TableCell>
                          <TableCell>
                            <a href={item?.webLink}>{item?.webLink}</a>
                          </TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>${item?.totalCost}</TableCell>
                          <TableCell>{item?.status}</TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-advertisements/details/" +
                                      item?._id,
                                    {
                                      state: { userStatus: "ACCEPTED" },
                                    }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}

                              <IconButton
                                onClick={() => {
                                  setDelete(true);
                                  setSelectedId(item?._id);
                                }}
                                // onClick={() => {
                                //   setOpen(true);
                                //   setSelectedId(item?._id);
                                // }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_advertisements.no_found || ""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
            <Pagination
              module={ads}
              page={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
            <WarnModal
              setOpen={setDelete}
              open={isDelete}
              name="AD"
              handleDelete={() => {
                handleDelete(deleteById, selectId, getUserAds);
              }}
            />
          </Card>
        ) : null}
        <DocumentViewer
          open={open}
          setOpen={setOpen}
          image={
            modalType === 1
              ? advertiserDetails?.documentFrontImage
              : advertiserDetails?.documentBackImage
          }
          type="IMAGE"
        />
      </div>
    </MainContainer>
  );
};

export default ServiceProviderDetails;
