import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useEffect, useState } from "react";
import {
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  useLazyGetFaqQuery,
} from "../../services/cms";
import { showError, showToast } from "../../constants";
import { FaqRes } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const Faq = () => {
  const translations=useTranslation()as any
  const [questionEn, setQuestionEn] = useState("");
  const [questionAr, setQuestionAr] = useState("");

  const [answerEn, setAnswerEn] = useState("");
  const [answerAr, setAnswerAr] = useState("");
  const [faqsData, setFaqsData] = useState<FaqRes[]>([]);

  const [loading,setLoading]=useState<boolean>(false)
  const [remove,setRemove]=useState<boolean>(false)

  const [getFaqData, { isLoading }] = useLazyGetFaqQuery();
  const [postFaq, postFaqData] = useAddFaqMutation();
  const [deleteFaq, deleteFaqData] = useLazyDeleteFaqQuery();

  const getAllFaqsHandler = async () => {
    try {
      const response = await getFaqData({}).unwrap();
      if (response?.statusCode === 200) {
        setFaqsData(response?.data?.data || []);
        // setLoading(false)
        
      }
    } catch (error: any) {
      showError(error?.data?.message ||"")
    }
  };
  const handleDeleteFaq = async (id: string) => {
    if(remove){
      return;
    }
   
    try {
      setRemove(true)
      const response = await deleteFaq({ faq_id: id }).unwrap();
      if (response?.statusCode === 200) {
        getAllFaqsHandler();
        showToast(translations.Globals.faq_del);
        setRemove(false)
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };

  const handleAddFaq = async () => {
  
    if(loading){
      return;
    }
    const body = {
      question: questionEn,
      question_ar: questionAr,
      answer: answerEn,
      answer_ar: answerAr,
      appKey: new Date().toISOString(),
    };


    
    try {
      setLoading(true)
      const res = await postFaq({ body }).unwrap();
      if (res?.statusCode === 200) {
        getAllFaqsHandler();
        showToast(translations.Globals.faq_add);
        setQuestionEn("");
        setQuestionAr("");
        setAnswerAr("");
        setAnswerEn("");
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllFaqsHandler();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.faq.manage_faq||""}</h1>
        </div>
        <div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Grid container spacing={2}>
                {faqsData?.length
                  ? faqsData?.map((item, index) => {
                      return (
                        <Grid key={index} item xs={12}>
                          <Box className="faq_box">
                            <FormControl sx={{ mb: 2, width: "100%" }}>
                              <Typography className="custom_label">
                              {translations.faq.ques_eng||""}
                              </Typography>
                              <TextField
                                hiddenLabel
                                type={"text"}
                                name="name"
                                multiline
                                minRows={1}
                                maxRows={4}
                                variant="outlined"
                                value={item?.question}
                                disabled
                                placeholder={translations.faq.ques_eng||""}
                              ></TextField>
                            </FormControl>
                            <FormControl sx={{ mb: 2, width: "100%" }}>
                              <Typography className="custom_label">
                              {translations.faq.ques_arb||""}
                              </Typography>
                              <TextField
                                hiddenLabel
                                type={"text"}
                                name="name"
                                variant="outlined"
                                multiline
                                minRows={1}
                                maxRows={4}
                                value={item?.question_ar}
                                placeholder= {translations.faq.ques_arb||""}
                                disabled
                              ></TextField>
                            </FormControl>
                            <FormControl sx={{ mb: 2, width: "100%" }}>
                              <Typography className="custom_label">
                              {translations.faq.ans_eng||""}
                              </Typography>
                              <TextField
                                hiddenLabel
                                type={"text"}
                                name="name"
                                multiline
                                minRows={1}
                                maxRows={4}
                                variant="outlined"
                                value={item?.answer}
                                placeholder= {translations.faq.ans_eng||""}
                                disabled
                              ></TextField>
                            </FormControl>

                            <FormControl sx={{ width: "100%" }}>
                              <Typography className="custom_label">
                              {translations.faq.ans_arb||""}
                              </Typography>
                              <TextField
                                hiddenLabel
                                type={"text"}
                                name="name"
                                variant="outlined"
                                multiline
                                minRows={1}
                                maxRows={4}
                                value={item?.answer_ar}
                                placeholder= {translations.faq.ans_arb||""}
                                disabled
                              ></TextField>
                            </FormControl>
                            <Box className="faq_btn">
                              <Button
                                variant="contained"
                                className="btn btn_primary sm"
                                onClick={() => handleDeleteFaq(item?._id)}
                              >
                                {translations.faq.remove||""}
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })
                  : null}

                <Grid item xs={12}>
                  <Box className="faq_box">
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                      {translations.faq.ques_eng||""}
                      </Typography>
                      <TextField
                        multiline
                        minRows={1}
                        maxRows={4}
                        hiddenLabel
                        type={"text"}
                        name="name"
                        variant="outlined"
                        value={questionEn}
                        // onChange={(val) => setQuestionEn(val.target.value)}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else  {
                            setQuestionEn(val.target.value);
                          }
                        }}
                        placeholder=   {translations.faq.ques_eng||""}
                      ></TextField>
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                      {translations.faq.ques_arb||""}
                      </Typography>
                      <TextField
                        hiddenLabel
                        multiline
                        minRows={1}
                        maxRows={4}
                        type={"text"}
                        name="name"
                        variant="outlined"
                        value={questionAr}
                        // onChange={(val) => setQuestionAr(val.target.value)}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else  {
                            setQuestionAr(val.target.value);
                          }
                        }}
                        placeholder={translations.faq.ques_arb||""}
                      ></TextField>
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                      {translations.faq.ans_eng||""}
                      </Typography>
                      <TextField
                        hiddenLabel
                        multiline
                        minRows={1}
                        maxRows={4}
                        type={"text"}
                        name="name"
                        variant="outlined"
                        value={answerEn}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else  {
                            setAnswerEn(val.target.value);
                          }
                        }}
                        // onChange={(val) => setAnswerEn(val.target.value)}
                        
                        placeholder=   {translations.faq.ans_eng||""}
                      ></TextField>
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                      {translations.faq.ans_arb||""}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="name"
                        multiline
                        minRows={1}
                        maxRows={4}
                        variant="outlined"
                        value={answerAr}
                        // onChange={(val) => setAnswerAr(val.target.value)}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else  {
                            setAnswerAr(val.target.value);
                          }
                        }}
                        placeholder={translations.faq.ans_arb||""}
                      ></TextField>
                    </FormControl>
                    <Box className="faq_btn">
                      <Button
                        variant="contained"
                        disabled={
                          !questionEn || !answerEn || !questionAr || !answerAr 
                        }
                        onClick={()=>{
                          // setLoading(true)
                          handleAddFaq()
                        }}
                        className="btn btn_primary sm"
                      >
                        {translations.faq.add||""}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </div>
      </div>
    </MainContainer>
  );
};

export default Faq;
